import React, { useEffect, useState } from "react";
import "./AdminOrderPage.css";
import { Link, useParams } from "react-router-dom";
import { BRIEF_API, CLIENT_API } from "../../constants/api";
import DataTable from "react-data-table-component";
import { Badge } from "reactstrap";
import { format } from 'date-fns';
function AdminOrderPage() {
  const { id } = useParams();
  const [briefs, setBriefs] = useState(null);
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem("token");

  const [projectCategory, setProjectCategory] = useState("");
  const [projectPrice, setProjectPrice] = useState("");
  const [projectPackage, setProjectPackage] = useState("");
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title.name,
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      maxWidth: "300px", 
    },
    // {
    //   name: "Card",
    //   selector: (row) => "**** **** 1207",
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "150px", 
    },
    {
      name: "Date Created",
      selector: (row) => 
        format(new Date(row.createdAt), "dd MMM, yyyy hh:mm a"),
      minWidth: "180px",
      maxWidth: "200px",
      grow: 2,
      sortable: true,
    },
    {
      name: "Expected Delivery",
      selector: (row) => 
        format(new Date(row.endDate), "dd MMM, yyyy hh:mm a"),
      minWidth: "180px",
      maxWidth: "200px",
      grow: 2,
      sortable: true,
    },
    {
      name: "Last Progress",
      selector: (row) => 
        format(new Date(row.updatedAt), "dd MMM, yyyy hh:mm a"),
      minWidth: "180px",
      maxWidth: "200px",
      grow: 2,
      sortable: true,
    },


    {
      name: "Action",
      selector: (row) => (
        <Link
          to={`/auth/admin-brief-page/${row.clientProjectId}/${row._id}`}
          className="btn btn-sm"
          style={{ color: "white", backgroundColor: "black" }}
        >
          Track
        </Link>
      ),
      width: "120px", 
    },
  ];
  useEffect(() => {
    const getProject = async () => {
      const response = await fetch(CLIENT_API + `/single/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        console.log(json);
        setProjectCategory(json.data.projectCategory.name);
        setProjectPackage(json.data.packageName);
        setProjectPrice(
          json.data.currency.toUpperCase() + "" + json.data.price
        );
      }
    };
    const getBriefs = async () => {


      try{

        setLoading(true)
        const response = await fetch(BRIEF_API + `/listByClientProject/${id}`);
        const json = await response.json();
        if (response.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            const formattedData = json.data.map((record) => {
              const createdAtDate = new Date(record.createdAt);
              const day = createdAtDate.getDate();
              const monthIndex = createdAtDate.getMonth();
              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              const month = months[monthIndex];
              const year = createdAtDate.getFullYear();
              const formattedDate = `${day} ${month}, ${year}`;
              return { ...record, formattedDate };
            });
            setBriefs(formattedData);
            setRecords(formattedData);
          }
        } else {
          setRecords([]);
        }

      }catch(error){

      }finally{
        setLoading(false)
      }
    


    };

    getProject();
    getBriefs();
  }, [id]);

  const setFilter = (value) => {
    const result = briefs.filter((record) => {
      return (
        // record._id.toString().includes(value.toLowerCase()) ||
        record.title.name.toLowerCase().includes(value.toLowerCase()) ||
        record.description.toLowerCase().includes(value.toLowerCase()) ||
        record.status.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.endDate.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.updatedAt.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.formattedDate
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    });

    setRecords(result);
  };

  const tableHeaderStyle = {
    headRow: {
      style: {
        backgroundColor: "#1F2123",
      },
    },
    headCells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        backgroundColor: "#1F2123",
        color: "#ffffff",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    paginationButton: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    table: {
      style: {
        padding: "10px",
        backgroundColor: "#1F2123",
      },
    },
  };

  return (
    <div className="container-fluid">
      <h2 className="mb-3">Briefs</h2>
      <h5 className="mb-5">
        {projectCategory}  {projectPackage === "Essential" || projectPackage === "E-commerce" ? (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #0f64a2)" }}
              className="ms-1"
            >
              {projectPackage}
            </Badge>
          ) : (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #d09c17)" }}
              className="ms-2"
            >
              {projectPackage}
            </Badge>
          )}
      </h5>

      <div className="header-body">
        <div className="row mb-1 d-flex justify-content-end">
          <div className="col-md-4 col-sm-6">
            <form action="">
              <input
                className={`form-control ${briefs == null ? "d-none" : ""} `}
                placeholder="Search"
                onChange={(e) => setFilter(e.target.value)}
                type="text"
              />
            </form>
          </div>
        </div>
        <div className="row">
          <DataTable
            columns={columns}
            data={records}
            pagination
            progressPending={loading}
              progressComponent={
                <div className="text-center my-2 bg-white">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
            // Add custom styles or classes here using Bootstrap classes
            className="table table-striped table-hover"
          />
        </div>
      </div>
    </div>
  );
}

export default AdminOrderPage;


import React, { useContext, useEffect, useState } from "react";

import "./Reel.css";
import { NavContext, ThreeContext } from "../../../LandingPage.jsx";

const touchThreshold = 150; 
function Reel() {
  const { index, setIndex } = useContext(NavContext);
  const {threeAnimation, setThreeAnimation} = useContext(ThreeContext)
  const [mute, setMute] = useState(false)
  let touchStartY = 0

  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if(event.deltaY > 0){
        setThreeAnimation(0)
        setIndex(3)
        var video = document.getElementById("first-section-second-background-video");
        if (video) {
          video.pause();
          video.currentTime = 0; // Resets current time to 0
          video.style.display = "none";
          document.getElementById("first-section-second-play-reel").style.display = "block";
        }
      }else{
        setIndex(1)
        var video = document.getElementById("first-section-second-background-video");

        if (video) {
          video.pause();
          video.currentTime = 0; // Resets current time to 0
          video.style.display = "none";
          document.getElementById("first-section-second-play-reel").style.display = "block";
        }
      }
    }
  };

  const handleTouchStart = (event) => {
    const target = event.currentTarget;
    const startY = event.touches[0].clientY;
    //  alert(startY)
    touchStartY = startY
  
  };

  const handleTouchMove = async (event) => {
   
    let x = touchStartY
    if (touchStartY !== 0) {
      const deltaY = event.touches[0].clientY - touchStartY;
      if (Math.abs(deltaY) > touchThreshold) {
        const direction = deltaY > 0 ? 1 : -1;
       if(direction === 1){
       
        
        setIndex(1)
       }else{
        setIndex(3) 
    

       }
        touchStartY = 0;
      }
  };
}

  const handleTouchEnd = () => {
    touchStartY = 0
  };
 
   
  useEffect(() => {


    const sectionOneElement = document.getElementById("section-reel");
    if (sectionOneElement) {
      sectionOneElement.addEventListener("wheel", handleWheel);
      sectionOneElement.addEventListener("touchstart", handleTouchStart);
      sectionOneElement.addEventListener("touchmove", handleTouchMove);
      sectionOneElement.addEventListener("touchend", handleTouchEnd);
    }


    return () => {
      console.log("removed 3")
      if (sectionOneElement) {
        sectionOneElement.removeEventListener("wheel", handleWheel);
        sectionOneElement.removeEventListener("touchstart", handleTouchStart);
        sectionOneElement.removeEventListener("touchmove", handleTouchMove);
        sectionOneElement.removeEventListener("touchend", handleTouchEnd)
      }
  
    };


  }, [index]); 
  const playVideo = () => {
    document.getElementById("first-section-second-background-video").style.display = "block";
    document.getElementById("first-section-second-background-video").style.opacity = "1";
    document.getElementById("first-section-second-play-reel").style.display = "none";

    document.getElementById("first-section-video-speaker").style.opacity = "1";
    document.getElementById("first-section-video-mute").style.opacity = "1";

    var video = document.getElementById("first-section-second-background-video")
    if(video){
     video.play()
   
    }
}
const muteVideo = () => {

    var video = document.getElementById("first-section-second-background-video")
    if(video){
     video.muted = true;
   
     setMute(true)
    }
   
     }
     const unMuteVideo = () => {
   
    var video = document.getElementById("first-section-second-background-video")
    if(video){
     video.muted = false;
     setMute(false)
    }
   
     }
  return (
    <div id="section-reel" className="section-reel d-flex position-relative">
         <video loop id="first-section-second-background-video" className="position-absolute">
        <source src="./show-reel.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <img id="first-section-video-mute" role="button" onClick={muteVideo} className={`reel-section-speaker position-absolute ${mute? "d-none": ""}`} src="./Speaker.svg" alt="" />
      <img id="first-section-video-speaker" role="button" onClick={unMuteVideo} className={`reel-section-speaker position-absolute ${mute? "": "d-none"}`} src="./Mute.svg" alt="" />
      <div className="container align-self-center">
      
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
         
            <h1 id="first-section-second-play-reel" onClick={playVideo}  role="button" className="text-center section-reel-heading hype-blue check-reel-animation-class">
              PLAY <span className="hype-red">REEL</span> 
            </h1> 
          
          </div> 
        </div>
       
      
      </div>
    </div>
  );
}

export default Reel;

import React from "react";
import "./SectionFourMobile.css";

function SectionFourMobile() {
  return (
    <div className="section-four-mobile d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <h1 className="text-center fs-1 fw-bold section-four-heading">
              We've changed the way content creation <br /> works for brands.
            </h1>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-10 ">
            <div className="row">
              <div className="col-md-4 sec-four-cols">
                <div className="card landing-page-card border-0">
                  <img
                    src="/media/section-four-star.svg"
                    className="card-img-top sec-four-image"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h2 className="card-title fs-2 fw-bold sec-four-card-title">subscribe</h2>
                    <p className="card-text sec-four-card-text">
                    Unlimited content created by a team of designers,
                    storytellers and content creators.
                    </p>
                 
                  </div>
                </div>
              </div>
              <div className="col-md-4 sec-four-cols">
              <div className="card landing-page-card border-0" >
                  <img
                    src="/media/section-four-request.svg"
                    className="card-img-top sec-four-image"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h2 className="card-title fs-2 fw-bold sec-four-card-title">request</h2>
                    <p className="card-text sec-four-card-text">
                    Make a request & get your content in <span className="fw-bold">48 hours</span>
                    </p>
                  
                  </div>
                </div>
              </div>
              <div className="col-md-4 sec-four-cols">
              <div className="card landing-page-card border-0" >
                  <img
                    src="/media/section-four-review.svg"
                    className="card-img-top sec-four-image"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h2 className="card-title fs-2 fw-bold sec-four-card-title">review</h2>
                    <p className="card-text sec-four-card-text">
                    Get your content reviewed and revised as many
                    times as you want.
                    </p>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFourMobile;

import React from "react";
import "./SectionFiveMobile.css";

function SectionFiveMobile() {
  return (
    <div className="section-five-mobile d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <h1 className="text-center fs-1 fw-bold section-five-heading">
              Don't just take our word for it
            </h1>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-10 text-center">
            <img
              className="img-fluid"
              style={{ height: "5vh" }}
              src="/media/Arrow.png"
              alt=""
            />
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-10">
            <h2 className="text-center sec-five-take-there fw-lighter">
              TAKE THEIRS!
            </h2>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-10">
           

            <div
              id="carouselExampleInterval"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval={10000}>
                <div className="row">
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      Top-notch work! Hype-X brings visionary ideas to life.
                    </p>
                  </div>
                  <img
                    src="/media/Qalb.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      Hype-X transformed our brand's presence and engagement
                      effortlessly with their exceptional creative content.
                    </p>
                  </div>
                  <img
                    src="/media/av_blk.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      HYPE X meticulously crafts masterpieces worthy of praise.
                    </p>
                  </div>
                  <img
                    src="/media/Bliss-logo.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
            </div>
                </div>
                <div className="carousel-item" data-bs-interval={10000}>
                <div className="row">
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      Top-notch work! Hype-X brings visionary ideas to life.
                    </p>
                  </div>
                  <img
                    src="/media/Qalb.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      Hype-X transformed our brand's presence and engagement
                      effortlessly with their exceptional creative content.
                    </p>
                  </div>
                  <img
                    src="/media/av_blk.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      HYPE X meticulously crafts masterpieces worthy of praise.
                    </p>
                  </div>
                  <img
                    src="/media/Bliss-logo.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
            </div>
                </div>
                <div className="carousel-item" data-bs-interval={10000}>
                <div className="row">
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      Top-notch work! Hype-X brings visionary ideas to life.
                    </p>
                  </div>
                  <img
                    src="/media/Qalb.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      Hype-X transformed our brand's presence and engagement
                      effortlessly with their exceptional creative content.
                    </p>
                  </div>
                  <img
                    src="/media/av_blk.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card landing-page-card border-0">
                  <div className="card-body text-center">
                    <p className="card-text sec-five-card-text">
                      HYPE X meticulously crafts masterpieces worthy of praise.
                    </p>
                  </div>
                  <img
                    src="/media/Bliss-logo.svg"
                    className="card-img-top sec-five-image"
                    alt="..."
                  />
                </div>
              </div>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-md-10">
            <h1 className="text-center fs-1 sec-five-heading-two fw-bold">
            AI CONTENT CAN TAKE A STEP BACK
            </h1>
            <p className="sec-five-card-text text-center">Unlimited, human-created content is ready to take its seat at the table.
            No more dealing with unreliable freelancers, expensive agencies, or impactless AI-generated content.</p>
            <p className="sec-five-card-text text-center">One flat monthly fee for unlimited content requests, delivered lightning-fast.</p>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-10 text-center">
            <a href="#section-six-mobile" className="btn section-five-cta">Subscribe Now</a>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default SectionFiveMobile;

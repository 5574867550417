import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import "./SectionThree.css";

import { NavContext, ThreeContext, ThreeOneContext } from "../../LandingPage";
const touchThreshold = 150;
function SectionThreeOne() {
  const [activeProject, setActiveProject] = useState("Social Media");
  const { index, setIndex } = useContext(NavContext);
  const {threeOneAnimation, setThreeOneAnimation} = useContext(ThreeOneContext)
  const {threeAnimation, setThreeAnimation} = useContext(ThreeContext)
  const [myIndex, setMyIndex] = useState(0);
  const oneRef = useRef(null);
  const twoRef = useRef(null);
  const threeRef = useRef(null);
  let touchStartY = 0;
 
  const setOneIndexFunction = () => {
    setIndex(4)
  }
  const setIndexFunction = () => {
    setThreeAnimation(1)
    setIndex(3)
  }
  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if (event.deltaY > 0) {
        setIndex(5);
      } else {
        setThreeAnimation(1)
        setIndex(3);
      }
    }
  };

  const handleTouchStart = (event) => {
    const target = event.currentTarget;
    const startY = event.touches[0].clientY;
    //  alert(startY)
    touchStartY = startY;
  };

  const handleTouchMove = async (event) => {
    let x = touchStartY;
    if (touchStartY !== 0) {
      const deltaY = event.touches[0].clientY - touchStartY;
      if (Math.abs(deltaY) > touchThreshold) {
        const direction = deltaY > 0 ? 1 : -1;
        if (direction === 1) {
          setIndex(3);
        } else {
          setIndex(5);
        }
        touchStartY = 0;
      }
    }
  };

  const handleTouchEnd = () => {
    touchStartY = 0;
  };

  useEffect(() => {
    const sectionOneElement = document.getElementById("section-three-one");
    if (sectionOneElement) {
      sectionOneElement.addEventListener("wheel", handleWheel);
      sectionOneElement.addEventListener("touchstart", handleTouchStart);
      sectionOneElement.addEventListener("touchmove", handleTouchMove);
      sectionOneElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      console.log("removed 3");
      if (sectionOneElement) {
        sectionOneElement.removeEventListener("wheel", handleWheel);
        sectionOneElement.removeEventListener("touchstart", handleTouchStart);
        sectionOneElement.removeEventListener("touchmove", handleTouchMove);
        sectionOneElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [index]);


  return (
    <div id="section-three-one" className="section-three d-flex">
      <div
        id="section-three-overlay"
        className="section-three-overlay d-flex justify-content-center align-items-center"
      >
        <img src="/media/spinner.gif" alt="" />
      </div>
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center fs-1 fw-bold section-three-heading">
              Featured Projects
            </h1>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-12">
            <button
              ref={oneRef}
              onClick={setIndexFunction}
              className={`section-three-tabs ${
                activeProject === "Branding" ? "active-project" : ""
              }`}
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching2"
              data-bs-slide-to="0"
            >
              Branding
            </button>
            <button
              ref={twoRef}
              onClick={setOneIndexFunction}
              className={`section-three-tabs ${
                activeProject === "Social Media" ? "active-project" : ""
              }`}
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching2"
              data-bs-slide-to="1"
            >
              Social Media
            </button>
            <button
              ref={threeRef}
              onClick={() => setIndex(5)}
              className={`section-three-tabs ${
                activeProject === "UI/UX" ? "active-project" : ""
              }`}
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching2"
              data-bs-slide-to="2"
            >
              UI/UX
            </button>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 sec-three-1st-col p-2">
                <img
                  className={`section-three-image ${threeOneAnimation === 1? "come-left-x-animation-class" : "come-right-x-animation-class"} `}
                  src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1719839660/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/on2nhrffbiqlkpf91tc1.png"
                  alt=""
                />
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 sec-three-2nd-col p-2">
                    <img
                      className={`section-three-image ${threeOneAnimation === 1? "come-left-x-animation-class" : "come-right-x-animation-class"} `}
                      src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718385821/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/vuqcafpooueimudf4udf.png"
                      alt=""
                    />
                  </div>
                  <div className="col-md-12 sec-three-3rd-col p-2">
                    <img
                      className={`section-three-image ${threeOneAnimation === 1? "come-left-x-animation-class" : "come-right-x-animation-class"} `}
                      src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1719838924/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/lsjocxybxxcifn43gdvv.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 sec-three-4rth-col p-2">
                <img
                  className={`section-three-image ${threeOneAnimation === 1? "come-left-x-animation-class" : "come-right-x-animation-class"} `}
                  src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718385495/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/he9y9mzyuzhhuqvjmcrd.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionThreeOne;

import React from 'react'

function PrivacyPolicy() {
  return (
    <>
   <div className='container py-5'>
   <h1>Privacy Policy</h1>
    <p>Last updated: 8th July, 2024</p>
    <p>This Privacy Policy describes how HypeX (the "Site", "we", "us", or "our") collects, uses, and discloses your personal information when you visit, use our services, or make a purchase from hype-x.ca (the "Site") or otherwise communicate with us (collectively, the "Services"). For purposes of this Privacy Policy, "you" and "your" means you as the user of the Services, whether you are a customer, website visitor, or another individual whose information we have collected under this Privacy Policy.</p>
    <p>Please read this Privacy Policy carefully. By using and accessing any of the Services, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not use or access any of the Services.</p>
    <h2>Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time, including to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will post the revised Privacy Policy on the Site, update the "Last updated" date, and take any other steps required by applicable law.</p>
    <h2>How We Collect and Use Your Personal Information</h2>
    <p>To provide the Services, we collect and have collected over the past 12 months personal information about you from a variety of sources, as set out below. The information that we collect and use varies depending on how you interact with us.</p>
    <p>In addition to the specific uses set out below, we may use the information we collect about you to communicate with you, provide the Services, comply with any applicable legal obligations, enforce any applicable terms of service, and to protect or defend the Services, our rights, and the rights of our users or others.</p>
    <h2>What Personal Information We Collect</h2>
    <p>The types of personal information we obtain about you depend on how you interact with our Site and use our Services. When we use the term "personal information", we are referring to information that identifies, relates to, describes, or can be associated with you. The following sections describe the categories and specific types of personal information we collect.</p>
    <h3>Information We Collect Directly from You</h3>
    <p>Information that you directly submit to us through our Services may include:</p>
    <ul>
        <li>Basic contact details including your name, address, phone number, and email.</li>
        <li>Order information includes your name, billing address, shipping address, payment confirmation, email address, and phone number.</li>
        <li>Account information including your username, password, security questions.</li>
        <li>Shopping information including the items you view, put in your cart, or add to your wishlist.</li>
        <li>Customer support information including the information you choose to include in communications with us, for example, when sending a message through the Services.</li>
    </ul>
    <p>Some features of the Services may require you to directly provide us with certain information about yourself. You may elect not to provide this information, but doing so may prevent you from using or accessing these features.</p>
    <h3>Information We Collect through Cookies</h3>
    <p>We also automatically collect certain information about your interaction with the Services ("Usage Data"). To do this, we may use cookies, pixels and similar technologies ("Cookies"). Usage Data may include information about how you access and use our Site and your account, including device information, browser information, information about your network connection, your IP address and other information regarding your interaction with the Services.</p>
    <h3>Information We Obtain from Third Parties</h3>
    <p>Finally, we may obtain information about you from third parties, including from vendors and service providers who may collect information on our behalf, such as:</p>
    <ul>
        <li>Companies who support our Site and Services, such as Shopify.</li>
        <li>Our payment processors, who collect payment information (e.g., bank account, credit or debit card information, billing address) to process your payment in order to fulfill your orders and provide you with products or services you have requested, in order to perform our contract with you.</li>
    </ul>
    <p>When you visit our Site, open or click on emails we send you, or interact with our Services or advertisements, we, or third parties we work with, may automatically collect certain information using online tracking technologies such as pixels, web beacons, software developer kits, third-party libraries, and cookies.</p>
    <p>Any information we obtain from third parties will be treated in accordance with this Privacy Policy. We are not responsible or liable for the accuracy of the information provided to us by third parties and are not responsible for any third party's policies or practices. For more information, see the section below, Third Party Websites and Links.</p>
    <h2>How We Use Your Personal Information</h2>
    <p>Providing Products and Services. We use your personal information to provide you with the Services in order to perform our contract with you, including to process your payments, fulfill your orders, to send notifications to you related to your account, purchases, returns, exchanges or other transactions, to create, maintain and otherwise manage your account, to arrange for shipping, facilitate any returns and exchanges and to enable you to post reviews.</p>
    <p>Marketing and Advertising. We use your personal information for marketing and promotional purposes, such as to send marketing, advertising and promotional communications by email, text message or postal mail, and to show you advertisements for products or services. This may include using your personal information to better tailor the Services and advertising on our Site and other websites.</p>
    <p>Security and Fraud Prevention. We use your personal information to detect, investigate or take action regarding possible fraudulent, illegal or malicious activity. If you choose to use the Services and register an account, you are responsible for keeping your account credentials safe. We highly recommend that you do not share your username, password, or other access details with anyone else. If you believe your account has been compromised, please contact us immediately.</p>
    <p>Communicating with you. We use your personal information to provide you with customer support and improve our Services. This is in our legitimate interests in order to be responsive to you, to provide effective services to you, and to maintain our business relationship with you.</p>
    <h2>Cookies</h2>
    <p>Like many websites, we use Cookies on our Site. For specific information about the Cookies that we use related to powering our store with Shopify, see <a href="https://hype-x.ca">https://hype-x.ca</a>. We use Cookies to power and improve our Site and our Services (including to remember your actions and preferences), to run analytics and better understand user interaction with the Services (in our legitimate interests to administer, improve and optimize the Services). We may also permit third parties and services providers to use Cookies on our Site to better tailor the services, products and advertising on our Site and other websites.</p>
    <p>Most browsers automatically accept Cookies by default, but you can choose to set your browser to remove or reject Cookies through your browser controls. Please keep in mind that removing or blocking Cookies can negatively impact your user experience and may cause some of the Services, including certain features and general functionality, to work incorrectly or no longer be available. Additionally, blocking Cookies may not completely prevent how we share information with third parties such as our advertising partners.</p>
    <h2>How We Disclose Personal Information</h2>
    <p>In certain circumstances, we may disclose your personal information to third parties for legitimate purposes subject to this Privacy Policy. Such circumstances may include:</p>
    <ul>
        <li>With vendors or other third parties who perform services on our behalf (e.g., IT management, payment processing, data analytics, customer support, cloud storage, fulfillment and shipping).</li>
        <li>With business and marketing partners, including Shopify, to provide services and advertise to you. [NOTE TO MERCHANT: INSERT THE FOLLOWING SENTENCE IF USING SHOPIFY’S AD SERVICES, SUCH AS SHOPIFY AUDIENCES] [For example, we use Shopify to support personalized advertising with third-party services]. Our business and marketing partners will use your information in accordance with their own privacy notices.</li>
        <li>When you direct, request us or otherwise consent to our disclosure of certain information to third parties, such as to ship you products or through your use of social media widgets or login integrations, with your consent.</li>
        <li>With our affiliates or otherwise within our corporate group, in our legitimate interests to run a successful business.</li>
        <li>In connection with a business transaction such as a merger or bankruptcy, to comply with any applicable legal obligations (including to respond to subpoenas, search warrants and similar requests), to enforce any applicable terms of service, and to protect or defend the Services, our rights, and the rights of our users or others.</li>
    </ul>
    <p>We have, in the past 12 months, disclosed to third parties all of the categories of personal information that we collect, for the business purposes set out above.</p>
    <h2>Third Party Websites and Links</h2>
    <p>The Site may contain links to other websites that are not owned or controlled by us, including social media platforms, Shopify, and other third party services. This Privacy Policy applies only to information collected by our Services. We are not responsible for the privacy practices of these other websites or the information they may collect (which may include IP address). We encourage you to read the privacy statements of each website that you visit.</p>
    <h2>Children</h2>
    <p>The Services are not directed to, and we do not intend to, or knowingly, collect or solicit personal information online from children under the age of 13. If we learn that we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us with personal information, please contact us using the contact information below.</p>
    <h2>Notice to California Residents</h2>
    <p>While we do not sell personal information in exchange for money, our use of Cookies may constitute a "sale" under California law. California residents have the right to opt out of the "sale" of personal information, which you can do by clicking the “Do Not Sell My Personal Information” link or by broadcasting the Global Privacy Control signal.</p>
    <p>If you are a California resident, you may also have the right to:</p>
    <ul>
        <li>Request access to the specific pieces of personal information we have collected about you in the 12 months preceding your request.</li>
        <li>Request to know more about how we collect, use, disclose, and sell personal information.</li>
        <li>Request deletion of your personal information.</li>
        <li>Not be discriminated against for exercising your rights set out above.</li>
    </ul>
    <p>California residents may make requests to access or delete certain personal information by emailing us at [Contact Information].</p>
    <h2>Notice to European Users</h2>
    <p>The information provided in this section applies only to individuals in the European Economic Area and the UK (collectively, “Europe”).</p>
    <h3>Controller</h3>
    <p>HypeX is the controller of your personal information covered by this Privacy Policy for purposes of European data protection legislation.</p>
    <h3>Legal Basis for Processing</h3>
    <p>We process your personal information for the purposes set out in the section “How We Use Your Personal Information” on one or more of the following legal grounds:</p>
    <ul>
        <li>Because the processing is necessary to perform a contract with you, such as to deliver products or services you have requested.</li>
        <li>Because we have a legitimate interest in processing your information. For example, we have a legitimate interest in processing your information to provide, secure, and improve our Services, in communicating with you about changes to our Services, and in informing you about new services or products.</li>
        <li>To comply with our legal obligations.</li>
        <li>Because you have provided your consent. If we process your personal information based on your consent, you may withdraw your consent at any time.</li>
    </ul>
    <h3>Your European Privacy Rights</h3>
    <p>If you are located in Europe, you may have the right to exercise certain privacy rights available to you under applicable laws, including the following rights:</p>
    <ul>
        <li>Right to access your personal information.</li>
        <li>Right to rectify your personal information.</li>
        <li>Right to erase your personal information.</li>
        <li>Right to restrict the processing of your personal information.</li>
        <li>Right to receive a copy of your personal information.</li>
        <li>Right to object to the processing of your personal information.</li>
    </ul>
    <p>To make a request to exercise your privacy rights, please contact us at [Contact Information]. We will consider and act upon any request in accordance with applicable data protection laws.</p>
    <p>If you are located in Europe and have any questions or concerns, please contact us using the contact information below.</p>
    <h2>Retention</h2>
    <p>We will retain your personal information for as long as necessary to provide you with the Services or as otherwise set forth in this Policy. We will also retain your personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at [Contact Information].</p>
   </div>
    </>
  )
}

export default PrivacyPolicy
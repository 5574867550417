import React, { useEffect, useState } from "react";
import { CONFIGURE_API, DEPARTMENT_API } from "../../constants/api";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
function AllIncludes() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [selected_id, setSelectedId] = useState("");
  const [myError, setMyError] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row.time,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.status ? "Active" : "Inactive"),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, row._id)}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <SettingsIcon style={{ color: "#828282", fontSize: "16px" }} />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom", // Change to bottom
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleClose}>
              <Button
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <EditIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <Link
                  to={`editService/${selected_id}`}
                  style={{ color: "#2C2C2C", textDecoration: "none" }}
                >
                  Edit
                </Link>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                onClick={() => handleDelete(selected_id)}
                style={{
                  color: "#2C2C2C",
                  fontFamily: "Source Sans Pro",
                  fontSize: "14px",
                }}
                startIcon={
                  <DeleteIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Delete
              </Button>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
              <Button
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
                style={{
                  color: "#2C2C2C",
                  fontFamily: "Source Sans Pro",
                  fontSize: "14px",
                }}
                startIcon={
                  <CancelIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
              >
                Suspend
              </Button>
            </MenuItem> */}
          </Menu>
        </div>
      ),
    },
  ];
  const [data1, setData] = useState(null);
  const [records, setRecords] = useState([]);
  const [allDepartments, setAllDepartments] = useState(null);

  const [update, setUpdate] = useState("");

  useEffect(() => {
    const fetchIncludes = async () => {
      try {
        setLoading(true);
        const response = await fetch(CONFIGURE_API + "/includes/list");
        const json = await response.json();

        if (response.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            setData(json.data);
            setRecords(json.data);
            console.log(myError);
          }
        } else {
          setRecords([]);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchIncludes();
  }, [update, myError]);
  const handleDelete = (myId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(
              CONFIGURE_API + "/includes/delete/" + d_id,
              {
                method: "PATCH",
              }
            );
            setUpdate(Math.random());
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Service has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Service is safe :)",
            icon: "error",
          });
        }
      });
  };
  const setFilter = (value) => {
    const result = data1.filter((record) => {
      return (
        // record._id.toString().includes(value.toLowerCase()) ||
        record.title.toLowerCase().includes(value.toLowerCase()) ||
        record.description.toLowerCase().includes(value.toLowerCase()) ||
  
        record.time.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setRecords(result);
  };
  return (
    <div className="container-fluid">
      <h2 className="mb-5">Services</h2>

      <div className="header-body">
        <div className="row mb-1 d-flex justify-content-end">
          <div className="col-md-4 col-sm-6">
            <form action="">
              <input
                className={`form-control ${data1 == null ? "d-none" : ""} `}
                placeholder="Search"
                onChange={(e) => setFilter(e.target.value)}
                type="text"
              />
            </form>
          </div>
        </div>

        <div className="row">
          <DataTable
            columns={columns}
            data={records}
            pagination
            progressPending={loading}
            progressComponent={
              <div className="text-center my-2 bg-white">
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
            // Add custom styles or classes here using Bootstrap classes
            className="table table-striped table-hover"
          />
        </div>
      </div>
    </div>
  );
}

export default AllIncludes;

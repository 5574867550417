import React from "react";
import { useState, createContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./AddNewPortfolio.css";
import PortfolioFGallery from "../../../components/featuredmediaGallery/PortfolioFGallery";
import PortfolioMediaGallery from "../../../components/mediaGallery/PortfolioMediaGallery";
import {
  MEDIA_API,
  PORTFOLIO_API,
  DEPARTMENT_API,
} from "../../../constants/api";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";

const pImage = createContext();
const gImage = createContext();

function AddNewPortfolio() {
  const [title, setTitle] = useState("");
  const [allImages, setAllImages] = useState(null);
  const [description, setDescription] = useState("");
  const [featuredImageId, setFeaturedImageId] = useState("");
  const [featuredImage, setFeaturedImage] = useState([]);
  const [gImageId, setgImageId] = useState([]);
  const [galleryImage, setgalleryImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [galleryLoading, setGalleryLoading] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [departmentId, setDepartmentId] = useState("");
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const [tags, setTags] = useState([]);
  const [departments, setDepartments] = useState(null);

  const onChange = (e) => {
    const value = e.detail.value;
    console.log("Tags onChange value:", value);
    try {
      setTags(value ? JSON.parse(value) : []);
    } catch (error) {
      console.error("Error parsing tags:", error);
      setTags([]);
    }
  };

  useEffect(() => {
    const getFeaturedImage = async () => {
      console.log(featuredImageId);
      if (featuredImageId !== "") {
        const response = await fetch(MEDIA_API + "/list");
        const json = await response.json();

        if (response.ok) {
          const myfeaturedImage = json.data
            .filter((image) => featuredImageId.includes(image._id))
            .map(({ public_id, secure_url }) => ({ public_id, secure_url }));
          setFeaturedImage(myfeaturedImage);
        }
      }
    };

    getFeaturedImage();
  }, [featuredImageId]);

  const filtergalleryImage = (allData) => {
    const mygalleryImage = allData
      .filter((image) => gImageId.includes(image._id))
      .map(({ _id, public_id, secure_url }) => ({
        _id,
        public_id,
        secure_url,
      }));
    setgalleryImage(mygalleryImage);
  };

  useEffect(() => {
    setGalleryLoading(true);

    const getgImage = async () => {
      try {
        console.log(gImageId);
        if (gImageId.length > 0) {
          const response = await fetch(MEDIA_API + "/list");
          const json = await response.json();

          if (response.ok) {
            setAllImages(json.data);
            filtergalleryImage(json.data);
            setGalleryLoading(false);
          }
        }
      } catch (error) {
      } finally {
        setGalleryLoading(false);
      }
    };

    getgImage();
  }, [gImageId]);

  useEffect(() => {
    const getDepartments = async () => {
      const response = await fetch(DEPARTMENT_API + "/list");
      const departments = await response.json();

      if (response.ok) {
        setDepartments(departments.data);
        console.log(departments.data);
      }
    };
    getDepartments();
  }, []);
  const handleRemoveFeaturedImage = () => {
    setFeaturedImageId("");
    setFeaturedImage([]);
  };

  const removeGalleryImage = (imageId, i) => {
    const index = gImageId.indexOf(imageId);

    let newImageId = [...gImageId];
    newImageId.splice(index, 1);
    setgImageId(newImageId);

    if (newImageId.length < 1) {
      let newGalleryImage = [...galleryImage];
      newGalleryImage.splice(i, 1);
      setgalleryImage(newGalleryImage);
    }
  };

  const handlePublish = async () => {
    setLoading(true);

    const loadingToastId = toast.loading("Publishing Portfolio...");

    const galleryImages = galleryImage.map((image) => {
      const { _id, ...rest } = image;
      return rest;
    });

    const service = {
      userId,
      title,
      description,
      departmentId,
      tags,
      isFeatured,
      featuredImage,
      galleryImages,
    };

    console.log(service);
    try {
      const response = await fetch(PORTFOLIO_API + "/create", {
        method: "POST",
        body: JSON.stringify(service),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("Portfolio published successfully!");
        setTitle("");
        setDescription("");
      }
    } catch (error) {
      console.error("Error publishing portfolio:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while publishing portfolio.");
    } finally {
      setLoading(false);

      console.log(featuredImage);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-light">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputEmail4">Portfolio Title</label>
                    <input
                      type="text"
                      placeholder="Enter title of portfolio"
                      onChange={(e) => setTitle(e.target.value)}
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card card-light">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputState">Department</label>
                    <select
                      onChange={(e) => setDepartmentId(e.target.value)}
                      id="inputState"
                      className="form-control"
                    >
                      <option value="" disabled selected>
                        Select Department
                      </option>
                      {departments &&
                        departments.map((singleDepartment) => (
                          <option
                            key={singleDepartment._id}
                            value={singleDepartment._id}
                          >
                            {singleDepartment.departName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-light">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputEmail4">Description</label>
                    <textarea
                      class="form-control"
                      placeholder="Describe your portfolio here"
                      id="exampleFormControlTextarea1"
                      rows="15"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="card card-light col-md-12">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="inputEmail4">Action</label>
                  <hr />
                  <div
                    className="px-4"
                    style={{ width: "fit-content", float: "left" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                      onChange={(e) => setIsFeatured(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Set as featured
                    </label>
                  </div>

                  {loading ? (
                    <div>Publishing...</div>
                  ) : (
                    <button
                      className="btn add-button float-right"
                      onClick={() => handlePublish()}
                    >
                      Publish
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card card-light col-md-12">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="inputEmail4">Tags</label>
                  {/* <Tags
                    value={tags}
                    onChange={onChange}
                    settings={{
                      placeholder: "Press enter to add tags",
                      delimiters: ", ", 
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="card card-light col-md-12">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="inputEmail4">Featured Image</label>
                  <hr />
                  {featuredImage.length > 0 ? (
                    <>
                      {" "}
                      <img
                        className="img-fluid"
                        src={featuredImage[0].secure_url}
                        alt=""
                      />
                      <p
                        type="button"
                        className="float-left set-service-image"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      >
                        <u>Change image</u>
                      </p>
                      <p
                        type="button"
                        className="float-right text-danger"
                        onClick={() => {
                          handleRemoveFeaturedImage();
                        }}
                      >
                        <u>Remove Image</u>
                      </p>
                    </>
                  ) : (
                    <p
                      type="button"
                      className="float-left set-service-image"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      <u>Set featured image</u>
                    </p>
                  )}

                  <pImage.Provider
                    value={{ featuredImageId, setFeaturedImageId }}
                  >
                    <PortfolioFGallery name="Portfolio Image" />
                  </pImage.Provider>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card card-light col-md-12">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="inputEmail4">Image Gallery</label>
                  <hr />
                  {galleryLoading ? (
                    <div className="text-center py-5">Loading images...</div>
                  ) : (
                    <div className="row">
                      {galleryImage.length > 0 &&
                        galleryImage.map((images, index) => (
                          <div
                            key={images._id}
                            className="col-md-4 px-1 pb-2 whole-div"
                          >
                            <button
                              className="cross"
                              onClick={() =>
                                removeGalleryImage(images._id, index)
                              }
                            >
                              x
                            </button>
                            <img
                              className="img-fluid image-div"
                              src={images.secure_url}
                              alt=""
                            />
                          </div>
                        ))}
                    </div>
                  )}
                  <div className="row mt-2">
                    <p
                      type="button"
                      className="float-left set-service-image"
                      data-toggle="modal"
                      data-target="#exampleModalCenter_2"
                    >
                      <u>Add image to gallery</u>
                    </p>
                    <gImage.Provider value={{ gImageId, setgImageId }}>
                      <PortfolioMediaGallery name="Media Gallery" />
                    </gImage.Provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewPortfolio;
export { pImage, gImage };

import React, { useState, useEffect } from "react";
import { PORTFOLIO_API } from "../../constants/api";


function AllPortfoliosPage() {
  
  const [portfolios, setPortfolios] = useState(null);

  useEffect(() => {
    const getPortfolios = async () => {
      const response = await fetch(PORTFOLIO_API + "/list");
      const portfolio = await response.json();

      if (response.ok) {
        setPortfolios(portfolio.data);
      }
    };
    getPortfolios();
  }, []);
  return (
    <div className="container py-5">
      <h3>All Projects</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Title</th>
          </tr>
        </thead>
        <tbody>
          {portfolios &&
            portfolios.map((portfolio, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{portfolio.title}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllPortfoliosPage;

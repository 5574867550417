import React, { useState, useEffect, createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./LandingPage.css";
import SectionOneComponent from "./sections/SectionOneComponent";
import MobileComponents from "./sections/MobileComponents";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
} from "reactstrap";

const NavContext = createContext();
const ButtonContext = createContext();
const ThreeOneContext = createContext();
const ThreeContext = createContext();
function LandingPage() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [threeOneAnimation, setThreeOneAnimation] = useState(0);
  const [threeAnimation, setThreeAnimation] = useState(0);
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
   
    if(index !== 2){
      var video = document.getElementById("first-section-second-background-video");
      if (video) {
        video.pause();
        video.currentTime = 0; // Resets current time to 0
        video.style.display = "none";
        document.getElementById("first-section-second-play-reel").style.display = "block";
      }
    }


}, [index]);

// const changeValue = () => {
//     if (index < 10) {
//       if(index === 2 || index === 6){
//         setThreeAnimation(0)
//       }else if(index === 4 || index === 5){
//         setThreeAnimation(1)
//       }
//         setIndex(index + 1);
//     }
// };

// const changeValueUp = () => {
//     if (index > 0) {
//       if(index === 2 || index === 6){
//         setThreeAnimation(0)
//       }else if(index === 4 || index === 5){
//         setThreeAnimation(1)
//       }
//         setIndex(index - 1);
//     }
// };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check initial window size
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      navigate("/");
      navigate(0);
    }
  }, []);

  const handleLogin = async () => {
    setClicked(true);

    await navigate("/login");
  };

  if (isMobile) {
    return (
      <div className="position-relative">
        <nav className="position-absolute w-100 top-0 end-0 position-fixed z-2">
          <Navbar color="white" light expand="lg">
            <NavbarBrand href="/"><img
              className="image-fluid"
              src="/media/Group6.png"
              width="80px"
              alt=""
            /></NavbarBrand> 
            <NavbarToggler
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              style={{border: "none"}}
            /> 
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink style={{fontFamily: "HelveticaNeueLTStd"}} href="#section-one-mobile">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{fontFamily: "HelveticaNeueLTStd"}} href="#section-three-mobile">Our Project</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{fontFamily: "HelveticaNeueLTStd"}} href="#section-six-mobile">Plans</NavLink>
                  <NavLink style={{fontFamily: "HelveticaNeueLTStd"}} href="#section-seven-mobile">Faqs</NavLink> 
                  <NavLink style={{fontFamily: "HelveticaNeueLTStd"}} href="https://hype-x.ca/blog">Blog</NavLink>
                  <NavLink style={{fontFamily: "HelveticaNeueLTStd"}} href="#section-eight-mobile">Contact Us</NavLink>
                  <NavLink style={{fontFamily: "HelveticaNeueLTStd"}} onClick={handleLogin}>Login</NavLink>
                </NavItem> 
              </Nav>
            </Collapse>
          </Navbar>
        </nav>

        <MobileComponents />
      </div>
    );
  }
  const handleGetStarted = (url) => {
    window.open(url, "_blank");
    // window.location.href = url;
  };
  return (
    <>
      <div id="landing-page" className="landing-page">
        <div className="nav-line row d-flex justify-content-center align-items-center">
          <div className="col-2 px-0 column-responsive">
            <button
              className={`${index === 0 || index === 1 || index === 2 ? "active" : ""}`}
              onClick={() => setIndex(0)}
            >
              Home
            </button>
          </div>
          <div className="col-auto p-0">
            <button
              className={`${
                index === 3 || index === 4 || index === 5 || index === 6 || index === 7 ? "active" : ""
              }`}
              onClick={() => setIndex(3)}
            >
              Our Projects
            </button>
          </div>

          <div className="col-auto p-0">
            <button
              className={`${index === 8 ? "active" : ""}`}
              onClick={() => setIndex(8)} 
            >
              Plans
            </button>
          </div>
          <div className="col-auto p-0">
            <button
              className={`${index === 9 ? "active" : ""}`}
              onClick={() => setIndex(9)}
            >
              FAQs
            </button>
          </div>
         
          <div className="col-auto p-0">
            <button
              className={`${index === 10 ? "active" : ""}`}
              onClick={() => setIndex(10)}
            >
              Contact Us
            </button>
          </div>
          <div className="col-auto p-0">
            <button onClick={() => handleGetStarted("https://hype-x.ca/blog")}>
              Blog
            </button>
          </div>
          <div className="col-auto px-0">
            <button className="login-nav-line-button" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>

   
        <NavContext.Provider value={{ index, setIndex }}>
          <ButtonContext.Provider value={{ clicked, setClicked }}>
          <ThreeOneContext.Provider value={{ threeOneAnimation, setThreeOneAnimation }}>
          <ThreeContext.Provider value={{ threeAnimation, setThreeAnimation }}>
            <SectionOneComponent />
          </ThreeContext.Provider>
          </ThreeOneContext.Provider>
          </ButtonContext.Provider>
        </NavContext.Provider>
      </div>
    </>
  );
}

export default LandingPage;
export { NavContext, ButtonContext, ThreeOneContext, ThreeContext };

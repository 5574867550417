import React from "react";
import "./SectionEightMobile.css";

function SectionEightMobile() {
  const handleBookCall = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div id="section-eight-mobile" className="section-eight-mobile d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <h1 className="text-center fs-1 fw-bold section-eight-heading">
              Let’s create unlimited content{" "}
              <span className="hype-blue">TOGETHER!</span>
            </h1>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center">
          <div className="col-md-8 d-flex justify-content-center">
            <div
              role="button"
              onClick={() =>
                handleBookCall("https://calendly.com/hypeappstore/30min")
              }
              className="sec-eight-book-1 d-flex align-items-center p-3 position-relative"
            >
              <img
                className="sec-eight-book-arrow-mobile"
                src="/media/book-arrow.png"
                alt=""
              />
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <h4 className="sec-seven-book-heading fw-bold">
                    Book a 15-min intro call
                  </h4>
                </div>

                <div className="col-12 d-flex align-items-center">
                  <p style={{fontSize: "14px"}} className="sec-seven-book-paragraph">
                    Learn how HypeX works.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center">
          <div className="col-md-8 d-flex justify-content-center">
            <div
              role="button"
              onClick={() =>
                handleBookCall("https://calendly.com/hypeappstore/30min")
              }
              className="sec-eight-book-2 d-flex align-items-center p-3 position-relative"
            >
              <img
                className="sec-eight-book-arrow-mobile"
                src="/media/book-arrow.png"
                alt=""
              />
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <h4 className="sec-seven-book-heading fw-bold">
                    Refer a friend & earn
                  </h4>
                </div>

                <div className="col-12 d-flex align-items-center">
                  <p style={{fontSize: "14px"}} className="sec-seven-book-paragraph">
                    Earn monthly commission for each referral.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center">
          <div className="col-md-8 d-flex justify-content-center">
            <h2 className="sec-eight-contact-us">Contact Us</h2>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-lg-8 d-flex justify-content-center">
            <div className="row w-75">
              <div className="col-md-4 col-sm-6 d-flex justify-content-start pl-0">
                <ul style={{ listStyleType: "none" }} className="pl-0">
                  <li id="facebook-footer-mobile">
                
                    <a
                      target="_blank"
                      className="footer-new-menu-links"
                      href="https://www.facebook.com/profile.php?id=61561453905409"
                    >
                      Facebook
                    </a>
                  </li>
                  {/* <li id="twitter-footer"> <img id="twitter-arrow" src="./social-arrow.svg" alt="" /> <a className="footer-new-menu-links" href="/#">Twitter / X</a></li> */}
                  <li id="instagram-footer-mobile">
               
                    <a
                      target="_blank"
                      className="footer-new-menu-links"
                      href="https://www.instagram.com/hypex_global/"
                    >
                      Instagram
                    </a>
                  </li>
                  <li id="linkedin-footer-mobile">
                
                    <a
                      target="_blank"
                      className="footer-new-menu-links"
                      href="https://www.linkedin.com/company/103917349/admin/dashboard/"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-6 d-flex justify-content-start pl-0">
                <div>
                  <p className="footer-new-menu-address footer-new-menu-address-mobile-1 pl-0">
                    229 Niagara Street,
                    <br />
                    Toronto, ON
                    <br />
                    M6J 2L5
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-right">
                <p className="footer-new-menu-address footer-new-menu-address-mobile-2 pl-0">
                  General Enquiries
                  <br />
                  <a className="x9Dcontact" href="mailto:info@hype-x.ca">
                    info@hype-x.ca
                  </a>
                  <br />
                  <a className="x9Dcontact" href="tel:+16474933336">
                    +1 (647) 4933336
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionEightMobile;

import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import {
  CLIENT_API,
  DEPARTMENT_API,
  MESSAGE_API,
  USER_API,
  PROPERTY_ID,
  CLIENT_ID,
  GA_TOKEN,
  BRIEF_API,
} from "../constants/api";
import { format } from 'date-fns';
import { Line } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";

import axios from "axios";
import { color } from "framer-motion";
import GoogleChart from "../components/GoogleChart";
import { Badge } from "reactstrap";
Chart.register(
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const StyledCell = styled.div`
  &.low {
    // background: linear-gradient(to right, #1f2123, #0f64a2) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &.medium {
    // background: linear-gradient(to right, #1f2123, #5b2b99) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &.high {
    // background: linear-gradient(to right, #1f2123, #d09c17) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
function Dashboard() {
  const propertyId = PROPERTY_ID;
  const startDate = "7daysAgo"; // Updated to relative dates
  const endDate = "today";

  const [activeUsersData, setActiveUsersData] = useState([]);
  const [activeSessionData, setActiveSessionData] = useState([]);
  const [pageViewsData, setPageViewsData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [dates, setDates] = useState([]);
  const [briefCounts, setBriefCount] = useState(0)
  const [completedCounts, setCompletedCounts] = useState(0)
  const [inCompletedCounts, setInCompletedCounts] = useState(0)
  const myId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const d_Id = localStorage.getItem("departmentId");
  const access_token = localStorage.getItem("access-token");
  const [loading, setLoading] = useState(true);
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalChats, setTotalChats] = useState(0);
  const [projects, setProjects] = useState(null);
  const [users, setUsers] = useState(null);
  const [records, setRecords] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [sessions, setSessions] = useState(0);
  const [pageViews, setPageViews] = useState(0);

  const fetchData = async () => {
    try {
      const requestBody = {
        dateRanges: [{ startDate, endDate }],
        dimensions: [{ name: "date" }],
        metrics: [
          { name: "activeUsers" },
          { name: "sessions" },
          { name: "screenPageViews" },
        ],
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      };

      const apiResponse = await axios.post(
        `https://analyticsdata.googleapis.com/v1beta/properties/${propertyId}:runReport`,
        requestBody,
        { headers }
      );

      const responseData = apiResponse.data;

      let totalActiveUsers = 0;
      let totalSessions = 0;
      let totalPageViews = 0;

      responseData.rows.forEach((row) => {
        totalActiveUsers += parseInt(row.metricValues[0].value, 10);
        totalSessions += parseInt(row.metricValues[1].value, 10);
        totalPageViews += parseInt(row.metricValues[2].value, 10);
      });

      setActiveUsers(totalActiveUsers);
      setSessions(totalSessions);
      setPageViews(totalPageViews);

      const newActiveUsersData = [];
      const newActiveSessionsData = [];
      const newPageViewsData = [];
      const newDates = [];

      responseData.rows.forEach((row) => {
        newDates.push(row.dimensionValues[0].value);
        newActiveUsersData.push(parseInt(row.metricValues[0].value, 10));
        newActiveSessionsData.push(parseInt(row.metricValues[1].value, 10));
        newPageViewsData.push(parseInt(row.metricValues[2].value, 10));
      });

      // Log the raw dates for debugging
      console.log("Raw dates:", newDates);
      // Combine dates and metrics into objects for sorting
      const combinedData = newDates.map((date, index) => ({
        date,
        activeUsers: newActiveUsersData[index],
        sessions: newActiveSessionsData[index],
        pageViews: newPageViewsData[index],
      }));

      // Log the combined data before sorting
      console.log("Combined data before sorting:", combinedData);

      combinedData.sort((a, b) => {
        const dateA = a.date.split("-").join("");
        const dateB = b.date.split("-").join("");
        return dateA - dateB;
      });

      // Separate sorted data back into arrays
      const sortedDates = combinedData.map((data) => data.date);
      const sortedActiveUsersData = combinedData.map(
        (data) => data.activeUsers
      );
      const sortedActiveSessionsData = combinedData.map(
        (data) => data.sessions
      );
      const sortedPageViewsData = combinedData.map((data) => data.pageViews);

      console.log("Sorted dates:", sortedDates);

      setDates(sortedDates);
      setActiveUsersData(sortedActiveUsersData);
      setActiveSessionData(sortedActiveSessionsData);
      setPageViewsData(sortedPageViewsData);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  };
  const fetchCountryData = async () => {
    try {
      const requestBody = {
        dateRanges: [{ startDate, endDate }],
        dimensions: [{ name: "country" }],
        metrics: [
          { name: "activeUsers" },
          // { name: "sessions" },
          // { name: "screenPageViews" },
        ],
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      };

      const apiResponse = await axios.post(
        `https://analyticsdata.googleapis.com/v1beta/properties/${propertyId}:runReport`,
        requestBody,
        { headers }
      );

      const responseData = apiResponse.data;

      const newActiveUsersData = [];

      const newCountries = [];

      responseData.rows.forEach((row) => {
        newCountries.push(row.dimensionValues[0].value);
        newActiveUsersData.push(parseInt(row.metricValues[0].value, 10));
      });

      const combinedData = newCountries.map((country, index) => ({
        country,
        activeUsers: newActiveUsersData[index],
      }));

      setCountries(newCountries);
      setCountryData(combinedData);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCountryData();
  }, [access_token]);

  const data0 = {
    labels: dates, // Replace with your actual timestamps

    datasets: [
      {
        label: "Active Users",
        data: activeUsersData, // Replace with your data values
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        pointRadius: 5,
        pointHitRadius: 10,
      },
    ],
  };
  const data1 = {
    labels: dates, // Replace with your actual timestamps
    datasets: [
      {
        label: "Sessions",
        data: activeSessionData, // Replace with your data values
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        pointRadius: 5,
        pointHitRadius: 10,
      },
    ],
  };
  const data2 = {
    labels: dates, // Replace with your actual timestamps
    datasets: [
      {
        label: "Page Views",
        data: pageViewsData, // Replace with your data values
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        pointRadius: 5,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true, // Adjust chart size based on container
    scales: {
      x: {
        type: "time", // Set the x-axis scale to time
        time: {
          unit: "day", // Adjust unit as needed (e.g., 'hour', 'month', 'year')
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at 0
      },
    },
  };

  function getCssClass(value) {
    if (value === "Essential" || value === "E-commerce") return "low";
    else if (value === "Premium") return "medium";
    return "high";
  }
  const columns = [
  
 
    {
      name: "Subscription",
      selector: (row) => (
        <>
          {row.projectCategory.name}
          {row.packageName === "Essential" || row.packageName === "E-commerce" ? (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #0f64a2)" }}
              className="ms-1"
            >
              {row.packageName}
            </Badge>
          ) : (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #d09c17)" }}
              className="ms-1"
            >
              {row.packageName}
            </Badge>
          )}
          
        </>
      ),
      sortable: true,
      // width: "fit-content", 
      minWidth: "270px",
      grow: 2,
    },
    { 
      name: "Name", 
      selector: (row) => {
       return users &&
        users.find(user => user._id === row.userId).username
      },
      sortable: true,
    },
    // {
    //   name: "Title",
    //   selector: (row) => row.projectTitle,
    //   sortable: true,
    // },
    {
      name: "Amount",
      selector: (row) => row.currency.toUpperCase() +" "+row.price,
      sortable: true,
      // width: "fit-content", 
      
      minWidth: "150px", 
    },
 
    {
      name: "Purchase Date",
      selector: (row) => {
        const createdAtDate = new Date(row.createdAt);
        const day = createdAtDate.getDate();
        const monthIndex = createdAtDate.getMonth();
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November", 
          "December",
        ];
        const month = months[monthIndex];
        const year = createdAtDate.getFullYear();
        const formattedDate = `${day} ${month}, ${year}`;
        return formattedDate;
      },
      sortable: true,
      minWidth: "150px", 
      // width: "fit-content", 
    },
    {
      name: "Expiry Date",
      selector: (row) => {
        const expiryDate = new Date(row.expiryDate);
        const currentDate = new Date();
    
        return expiryDate < currentDate ? 
          <span className="bg-danger p-2 text-white" style={{borderRadius: "30px"}}>
            {format(expiryDate, 'dd MMM, yyyy')}
          </span> : 
          format(expiryDate, 'dd MMM, yyyy');
      },
      sortable: true,
      minWidth: "150px",
    },
  
    // {
    //   name: "Status",
    //   selector: (row) => "In Progress",
    //   sortable: true,
    // },
    // {
    //   name: "Package",
    //   selector: (row) => row.packageName,
    //   sortable: true,
    //   cell: (row) => (
        
        //   row.packageName === "Essential" || row.packageName === "E-commerce"?

        //   <Badge style={{background: "linear-gradient(to right, #1f2123, #0f64a2)"}} className="ms-3">
        //   {row.packageName} 
        //  </Badge>
        //   :
        //   <Badge style={{background: "linear-gradient(to right, #1f2123, #d09c17)"}} className="ms-3">
        //  {row.packageName}
        // </Badge>
        
       
    //   ),
    // },
    {
      name: "View Briefs",
      selector: (row) => ( 
        <Link to={`/auth/admin-order-page/${row._id}`} className="btn btn-sm" style={{color: "white", backgroundColor: "black"}}>View Briefs</Link> 
      ),
      minWidth: "150px", 
      // minWidth: "100px",
    }, 
   
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(USER_API + "/users/list");
      const json = await response.json();
      if (response.ok) {
        setUsers(json.data);
      }
    };
    const getProjects = async () => {
 
      try{
        setLoading(true)
        const response = await fetch(CLIENT_API + "/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const json = await response.json();
        if (response.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            const formattedData = json.data.map((record) => {
              const createdAtDate = new Date(record.createdAt);
              const day = createdAtDate.getDate();
              const monthIndex = createdAtDate.getMonth();
              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              const month = months[monthIndex];
              const year = createdAtDate.getFullYear();
              const formattedDate = `${day} ${month}, ${year}`;
              return { ...record, formattedDate };
            });
            setProjects(formattedData);
            setRecords(formattedData);
            setTotalProjects(json.data.length);
          }
        } else {
          setRecords([]);
        }
      

      }catch(error){

      }finally{
        setLoading(false)
      }
    
    };
    const getAdminChats = async () => {
      if (role === "super-admin") {
        const response = await fetch(MESSAGE_API + "/conversationIds");
        const admins = await response.json();
        if (response.ok) {
          setTotalChats(admins.data.length);
        }
      } else {
        const response = await fetch(MESSAGE_API + "/sender/" + myId);
        const admins = await response.json();
        if (response.ok) {
          setTotalChats(admins.data.length);
        }
      }
    };
    const getBriefs = async () => {
      const response = await fetch(BRIEF_API + "/"+ myId);
      const briefs = await response.json();
    
      if (response.ok) {
        if (briefs.data.briefCount == null) {
          // alert("data is null")
        } else {
          setBriefCount(briefs.data.briefCount);
          setCompletedCounts(briefs.data.completedBriefs);
          setInCompletedCounts(briefs.data.briefsInProgress);
        }
      }
    };

    fetchUsers();
    getAdminChats();
    getProjects();
    getBriefs();
  }, [myId]);

  const setFilter = (value) => {
    const result = projects.filter((record) => {
      return (
        // record._id.toString().includes(value.toLowerCase()) ||

        record.currency.toLowerCase().includes(value.toLowerCase()) ||
        record.price.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.projectCategory.name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        record.packageName.toLowerCase().includes(value.toLowerCase()) ||
        record.formattedDate
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) 
   
      );
    });

    setRecords(result);
  };


  return (
    <>
   
            {/* {role === "super-admin" ? (
              <>
                <div className="row mt-3">
                  <div className="col-lg-4 admin-dashboard-analytics-cards p-4">
                    <h2 className=" admin-dashboard-top-cards-heading">
                      Active Users:{" "}
                      <span className="text-white">{activeUsers}</span>{" "}
                    </h2>
                    <Line data={data0} options={options} />
                  </div>
                  <div className="col-lg-4 admin-dashboard-analytics-cards p-4">
                    <h2 className=" admin-dashboard-top-cards-heading">
                      Sessions: <span className="text-white">{sessions}</span>
                    </h2>
                    <Line data={data1} options={options} />
                  </div>
                  <div className="col-lg-4 admin-dashboard-analytics-cards p-4">
                    <h2 className=" admin-dashboard-top-cards-heading">
                      Page Views:{" "}
                      <span className="text-white">{pageViews}</span>
                    </h2>
                    <Line data={data2} options={options} />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 p-4 admin-dashboard-analytics-cards">
                    <div className="row">
                      <div className="col-8">
                        <GoogleChart data={countryData} />
                      </div>
                      <div className="col-4 d-flex align-items-center">
                        <div className="container-fluid">
                          <table className="table table-dark">
                            <thead>
                              <tr>
                                <th scope="col">Countries</th>
                                <th scope="col">Users</th>
                              </tr>
                            </thead>
                            <tbody>
                              {countryData &&
                                countryData.map((data) => (
                                  <tr>
                                    <td>
                                      {data.country === "(not set)"
                                        ? "unknown"
                                        : data.country}
                                    </td>
                                    <td>{data.activeUsers}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )} */}

<div className="container-fluid">
  <h2 className="mb-5">Dashboard</h2>
  <div className="header-body">
    <div className="row">
    <div className="col-xl-3 col-lg-6">
        <div className="card card-stats mb-4 mb-xl-0">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-1">Progress</h5>
                <span className="h2 font-weight-bold mb-0">{inCompletedCounts}</span>
              </div>
              <div className="col-auto">
                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                <i className="lni lni-bar-chart"></i>
                </div>
              </div>
            </div>
            <p className="mt-3 mb-0 text-muted text-sm">
              {/* <span className="text-success mr-2"><i className="fas fa-arrow-up" /> 12%</span> */}
              <span className="text-nowrap">In progress briefs</span>
            </p>
          </div>
        </div>
      </div>
    <div className="col-xl-3 col-lg-6">
        <div className="card card-stats mb-4 mb-xl-0">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-1">Completed</h5>
                <span className="h2 font-weight-bold mb-0">{completedCounts}</span>
              </div>
              <div className="col-auto">
                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                <i className="lni lni-checkmark-circle"></i>
                </div>
              </div>
            </div>
            <p className="mt-3 mb-0 text-muted text-sm">
              {/* <span className="text-warning mr-2"><i className="fas fa-arrow-down" /> 1.10%</span> */}
              <span className="text-nowrap">Completed briefs</span>
            </p>
          </div>
        </div>
      </div>
    
      {/* packages  */}
      <div className="col-xl-3 col-lg-6">
        <div className="card card-stats mb-4 mb-xl-0">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-1">Subscriptions</h5>
                <span className="h2 font-weight-bold mb-0">{totalProjects}</span>
              </div>
              <div className="col-auto">
                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
        
                <i className="lni lni-layers"></i>
                </div>
              </div>
            </div>
            <p className="mt-3 mb-0 text-muted text-sm">
              {/* <span className="text-success mr-2"><i className="fa fa-arrow-up" /> 3.48%</span> */}
              <span className="text-nowrap">Total subscriptions</span>
            </p>
          </div>
        </div>
      </div>
       {/* end packages  */}
      <div className="col-xl-3 col-lg-6">
        <div className="card card-stats mb-4 mb-xl-0">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-1">Briefs</h5>
                <span className="h2 font-weight-bold mb-0">{briefCounts}</span>
              </div>
              <div className="col-auto">
                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                <i className="lni lni-inbox"></i>
                </div>
              </div>
            </div>
            <p className="mt-3 mb-0 text-muted text-sm">
              {/* <span className="text-danger mr-2"><i className="fas fa-arrow-down" /> 3.48%</span> */}
              <span className="text-nowrap">Total briefs</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* {
    role === "super-admin"?
    <div className="header-body mt-5">
    <div className="row">
      <div className="col-xl-4 col-lg-6">
        <div className="card card-stats mb-4 mb-xl-0">
          <div className="card-body">
          <Line data={data0} options={options} />
          </div>
        </div>
      </div>
    
      <div className="col-xl-4 col-lg-6">
        <div className="card card-stats mb-4 mb-xl-0">
          <div className="card-body">
          <Line data={data1} options={options} />
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-6">
        <div className="card card-stats mb-4 mb-xl-0">
          <div className="card-body">
          <Line data={data2} options={options} />
          </div>
        </div>
      </div>
    </div>
  </div>
  :
  <></>
  } */}

  <div className="header-body mt-5">
    <div className="row mb-1 d-flex justify-content-end">
      <div className="col-md-4 col-sm-6">
        <form action="">
        <input
              className={`form-control ${
                projects == null ? "d-none" : ""
              } `}
              placeholder="Search"
              onChange={(e) => setFilter(e.target.value)}
              type="text"
            />
        </form>
   
      </div>
 
    </div>
    <div className="row">
    
    <DataTable
        columns={columns}
        data={records}
        pagination
        progressPending={loading}
        progressComponent={
          <div className="text-center my-2 bg-white">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
   
        // Add custom styles or classes here using Bootstrap classes
        className="table table-striped table-hover"
      />

    </div>
  </div>
</div>
    </>
  );
}

export default Dashboard;

import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CATEGORY_API, CONFIGURE_API, COUNTRY_API } from "../../constants/api";
import Select from "react-select";

function EditPackage() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [services, setServices] = useState([]);
  const [briefs, setBriefs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [category, setCategory] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [stripe, setStripe] = useState("");
  const [price, setPrice] = useState("");
  const [requests, setRequests] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    const fetchSinglePackage = async () => {
      const response = await fetch(CONFIGURE_API + "/packages/singlePackage/" + id);
      const json = await response.json();

      if (response.ok) {
        setName(json.data.name || "");
        setType(json.data.type || "");
        setStatus(json.data.status || true);
        setServices(json.data.services || []);
        setCategory(json.data.category || "");
        setCountry(json.data.country || "");
        setStripe(json.data.stripe || "");
        setPrice(json.data.price || "");
        setRequests(json.data.requests || "");
        setDescription(json.data.description || "");
      }
    };

    fetchSinglePackage();
  }, [id]);

  useEffect(() => {
    const fetchIncludes = async () => {
      if (!briefs.length) {
        const response = await fetch(CONFIGURE_API + "/includes/list");
        const json = await response.json();
        if (response.ok) {
          const options = json.data.map((brief) => ({
            value: brief._id,
            label: brief.title,
          }));
          setBriefs(options);
        }
      }
    };

    const fetchCategories = async () => {
      if (!categories.length) {
        const response = await fetch(CATEGORY_API + "/list");
        const json = await response.json();
        if (response.ok) {
          setCategories(json.data);
        }
      }
    };

    const fetchCountries = async () => {
      if (!countries.length) {
        const response = await fetch(COUNTRY_API + "/list");
        const json = await response.json();
        if (response.ok) {
          const options = json.data.map((country) => ({
            value: country._id,
            label: country.name,
          }));
          setCountries(options);
        }
      }
    };

    fetchIncludes();
    fetchCategories();
    fetchCountries();
  }, [briefs, categories, countries]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Submitting Package Details...");

    const packages = {
      name,
      type,
      description,
      services,
      stripe,
      status,
      price,
      category,
      country,
      requests
    };

    try {
      const response = await fetch(CONFIGURE_API + `/packages/update/${id}`, {
        method: "PUT",
        body: JSON.stringify(packages),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      } else {
        toast.dismiss(loadingToastId);
        navigate("/auth/allPackages")
        toast.success("Package updated successfully!");
      }
    } catch (error) {
      console.error("Error updating Package:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while updating.");
    } finally {
      setLoading(false);
    }
  };

  const handleServiceChange = (selectedOptions) => {
    setServices(selectedOptions ? selectedOptions.map((opt) => opt.value) : []);
  };

  return (
    <div className="container-fluid">
      <h2 className="mb-5">Edit Package</h2>
      <div className="header-body">
        <div className="card">
          <div className="card-body">
            <form
              onSubmit={handleSubmit}
              className="row g-3 needs-validation"
              noValidate
            >
              <div className="col-md-4">
                <label htmlFor="inputName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  value={name}
                  id="inputName"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputCategory" className="form-label">
                  Category
                </label>
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  id="inputCategory"
                  className="form-select"
                  value={category}
                >
                  <option value="" disabled>
                    Choose...
                  </option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputType" className="form-label">
                  Type
                </label>
                <select
                  onChange={(e) => setType(e.target.value)}
                  id="inputType"
                  className="form-select"
                  value={type}
                >
                  <option value="Essential">Essential</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
              <div className="col-md-12">
                <label htmlFor="inputDescription" className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputDescription"
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-md-8">
                <label htmlFor="inputServices" className="form-label">
                  Services
                </label>
                <Select
                  isMulti
                  onChange={handleServiceChange}
                  options={briefs}
                  value={services.map((service) =>
                    briefs.find((brief) => brief.value === service)
                  )}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputPrice" className="form-label">
                  Requests at a Time
                </label>
                <input
                  type="number"
                  onChange={(e) => setRequests(e.target.value)}
                  className="form-control"
                  id="inputPrice"
                  value={requests}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputStripe" className="form-label">
                  Stripe URL
                </label>
                <input
                  type="text"
                  onChange={(e) => setStripe(e.target.value)}
                  className="form-control"
                  id="inputStripe"
                  value={stripe}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputPrice" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                  className="form-control"
                  id="inputPrice"
                  value={price}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputCountry" className="form-label">
                  Country
                </label>
                <Select
                  options={countries}
                  onChange={(e) => setCountry(e.value)}
                  value={countries.find((option) => option.value === country)}
                />
              </div>
              <div className="col-md-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={status}
                    id="flexCheckIndeterminate"
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckIndeterminate"
                  >
                    Active
                  </label>
                </div>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-dark float-right"
                  style={{ color: "white", background: "black" }}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPackage;

import React from "react";
import { useEffect, useState } from "react";
import "./SectionOneMobile.css";
import Marquee from "react-fast-marquee";
import { BRAND_API } from "../../../constants/api";

function SectionOneMobile() {
  const [brandLogos, setBrandLogos] = useState(null);
  useEffect(() => {
    const getBrandLogos = async () => {
      const response = await fetch(BRAND_API + "/list");
      const logos = await response.json();

      if (response.ok) {
        setBrandLogos(logos.data);
      } else {
        alert("Brands logos not found");
      }
    };

    getBrandLogos();
  }, []);
  return (
    <div id="section-one-mobile" className="section-one-mobile d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 d-flex justify-content-center">
            <p className="align-self-center section-one-desc">
              CONTENT SUBSCRIPTIONS FOR EVERYONE WITH <br />
              UNLIMITED DESIGNS, VIDEOS AND COPYWRITING
            </p>
            {/* <img
              className="image-fluid section-one-logo"
              src="/media/Group6.png"
              alt=""
            /> */}
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-8">
            <h1 className="text-center section-one-heading">
              THE NEW WAY <br />
              TO <span className="hype-red">CREATE</span>
              <br />
              <span className="hype-blue">CONTENT</span>
            </h1>
          </div>
        </div>
        <div className="row my-2 d-flex justify-content-center">
          <div className="col-md-8 text-center">
            <a href="#section-six-mobile" className="btn section-one-cta">See Plans</a>
          </div>
        </div>
        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-md-8 px-2">
            <Marquee>
              {brandLogos &&
                brandLogos.map((brandLogo) => (
                  <div className="d-flex flex-row">
                    <div className="mx-2">
                      <img
                        className="section-one-marquee-image"
                        src={brandLogo.secure_url}
                        alt=""
                      />
                    </div>
                    <div className="mx-2">
                      <img
                        className="section-one-marquee-dot"
                        src="/media/marqee-dot.svg"
                        alt=""
                      />
                    </div>
                  </div>
                ))}
            </Marquee>
          </div>
        </div>
        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-md-8 text-center d-flex justify-content-center">
            <img src="/media/DavidOutline-mobile.svg" className="section-one-mobile-david img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOneMobile;

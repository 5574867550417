import { React, useState } from "react";
import { Link } from "react-router-dom";
import "./Settings.css";
import { toast } from "react-toastify";
import { USER_API } from "../../constants/api";

function Settings() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Saving settings...");
    const user = {
      username,
      email,
      password,
      firstname,
      lastname,
      website,
      role,
    };

    try {
      const response = await fetch(USER_API+"/register", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("User Created successfully!");
        setUsername("");
        setPassword("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setWebsite("");
        setRole("");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while saving settings.");
    } finally {
      setLoading(false);
    }
  };

  return (
   
    <form onSubmit={handleSubmit}>
    <div className="card-body setting-card-body">
      <div className="form-row">
        <div className="form-group col-md-3 d-flex align-items-center">
          <label htmlFor="inputEmail4">Site Title</label>
        </div>
        <div className="form-group pl-0 col-md-4">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control"
            id="inputEmail4"
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-12">
          <div className="row">
            <div className="form-group col-md-3 d-flex align-items-center">
              <label htmlFor="inputEmail4">Tagline</label>
            </div>
            <div className="form-group pl-0 col-md-4">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-control"
                id="inputEmail4"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-9 pl-0">
              <p className="form-instructions">
                In a few words, explain what this site is about.
                Example: “Just another WordPress site.”
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="form-group col-md-3 d-flex align-items-center">
          <label htmlFor="inputEmail4">Site Address (URL)</label>
        </div>
        <div className="form-group col-md-4 pl-0">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="https://example.com/"
            className="form-control"
            id="inputEmail4"
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-12">
          <div className="row">
            <div className="form-group col-md-3 d-flex align-items-center">
              <label htmlFor="inputEmail4">
                Administration Email Address
              </label>
            </div>
            <div className="form-group col-md-4 pl-0">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-control"
                id="inputEmail4"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-9 pl-0">
              <p className="form-instructions">
                This address is used for admin purposes. If you
                change this, an email will be sent to your new
                address to confirm it. The new address will not
                become active until confirmed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="form-group col-md-3 d-flex align-items-center">
          <label htmlFor="inputEmail4">Membership</label>
        </div>
        <div className="form-group col-md-4 pl-0">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue
              id="flexCheckDefault"
            />
            <label
              className="form-check-label"
              htmlFor="flexCheckDefault"
            >
              Anyone can register
            </label>
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="form-group col-md-3 d-flex align-items-center">
          <label htmlFor="inputEmail4">
            New User Default Role
          </label>
        </div>
        <div className="form-group col-md-2 pl-0">
          <select
            onChange={(e) => setRole(e.target.value)}
            id="inputState"
            className="form-control"
          >
            <option value="user" selected>
              Subscriber
            </option>
            <option value="super-admin">Super Admin</option>
            {/* <option>Author</option> */}
            {/* <option>Editor</option> */}
            <option value="admin">Admin</option>
          </select>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="form-group col-md-3 d-flex align-items-center">
          <label htmlFor="inputEmail4">
            Site Language <i class="fas fa-language"></i>
          </label>
        </div>
        <div className="form-group col-md-3 pl-0">
          <select
            onChange={(e) => setRole(e.target.value)}
            id="inputState"
            className="form-control"
          >
            <option value="user" selected>
              English (United States)
            </option>
            <option value="super-admin">العربية</option>
            <option value="super-admin">Spanish</option>
            <option value="super-admin">French</option>
          </select>
        </div>
      </div>
    </div>
    {/* /.card-body */}
    <div className="card-footer bg-white">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <button type="submit" className="btn add-new-button">
          Save Changes
        </button>
      )}
    </div>
  </form>
  );
}

export default Settings;


import React, { useContext, useEffect } from "react";

import "./SectionTwo.css";
import { NavContext } from "../../LandingPage";

const touchThreshold = 150; 
function SectionTwo() {
  const { index, setIndex } = useContext(NavContext);
  let touchStartY = 0

  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if(event.deltaY > 0){
        setIndex(2)
      }else{
        setIndex(0)
      }
    }
  };

  const handleTouchStart = (event) => {
    const target = event.currentTarget;
    const startY = event.touches[0].clientY;
    //  alert(startY)
    touchStartY = startY
  
  };

  const handleTouchMove = async (event) => {
   
    let x = touchStartY
    if (touchStartY !== 0) {
      const deltaY = event.touches[0].clientY - touchStartY;
      if (Math.abs(deltaY) > touchThreshold) {
        const direction = deltaY > 0 ? 1 : -1;
       if(direction === 1){
        setIndex(1)
       }else{
        setIndex(2)
       }
        touchStartY = 0;
      }
  };
}

  const handleTouchEnd = () => {
    touchStartY = 0
  };
 
   
  useEffect(() => {


    const sectionOneElement = document.getElementById("section-two");
    if (sectionOneElement) {
      sectionOneElement.addEventListener("wheel", handleWheel);
      sectionOneElement.addEventListener("touchstart", handleTouchStart);
      sectionOneElement.addEventListener("touchmove", handleTouchMove);
      sectionOneElement.addEventListener("touchend", handleTouchEnd);
    }


    return () => {
      console.log("removed 3")
      if (sectionOneElement) {
        sectionOneElement.removeEventListener("wheel", handleWheel);
        sectionOneElement.removeEventListener("touchstart", handleTouchStart);
        sectionOneElement.removeEventListener("touchmove", handleTouchMove);
        sectionOneElement.removeEventListener("touchend", handleTouchEnd)
      }
  
    };


  }, [index]); 
  return (
    <div id="section-two" className="section-two d-flex">
      <div className="container align-self-center">
      
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center section-two-heading hype-blue check-two-animation-class-2">
              UNLIMITED CONTENT
            </h1>
         
            <h1 className="text-center section-two-heading hype-gray check-two-animation-class-1">
              AT YOUR <span className="hype-red">FINGERTIPS</span> 
            </h1>
          
          </div> 
        </div>
       
      
      </div>
    </div>
  );
}

export default SectionTwo;

import React, { useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  useRoutes,
  Route,
  Routes,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Suspense } from "react";
import Loader from "./layouts/loader/Loader";
import { Router } from "./routes/Router.js";
import FullLayout from "./layouts/FullLayout";
import Starter from "./views/Starter";
import LandingPage from "./landingpage/LandingPage.jsx";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import AllUsers from "./pages/users/allusers/AllUsers";
import AddNewUser from "./pages/users/newuser/AddNewUser";
import AllServices from "./pages/services/allservices/AllServices";
import AddNewService from "./pages/services/newservice/AddNewService";
import EditUser from "./pages/users/edituser/EditUser";
import Login from "./admin/Login";
import PrivateRoutes from "./components/privateroutes/PrivateRoutes";
import Settings from "./pages/settings/Settings";
import Brand from "./pages/settings/brand/Brand";
import Faqs from "./pages/settings/faqs/Faqs";
import Mailbox from "./pages/mailbox/Mailbox";
import ClientDashboard from "./pages/clientpages/clientdashboard/ClientDashboard";
import ClientLogin from "./client/ClientLogin";
import ClientPrivateRoutes from "./components/privateroutes/ClientPrivateRoutes";
import Department from "./pages/department/Department";
import ProjectBrief from "./landingpage/userlogin/project_brief/ProjectBrief";
import Orders from "./pages/clientpages/orders/Orders";
import Inbox from "./pages/clientpages/inbox/Inbox";
import Brief from "./pages/clientpages/brief/Brief";
import AdminOrders from "./pages/orders/AdminOrders";
import AllPortfolios from "./pages/portfolio/allportfolio/AllPortfolios";
import AddNewPortfolio from "./pages/portfolio/newportfolio/AddNewPortfolio";
import AllPortfoliosPage from "./landingpage/allportfoliospage/AllPortfoliosPage";
import SinglePortfolio from "./landingpage/sections/singleportfolio/SinglePortfolio";
import Team from "./pages/crm/team/Team";
import WorkPlace from "./pages/crm/workplace/WorkPlace";
import ClientOrderPage from "./pages/clientpages/clientOrderPage/ClientOrderPage";
import Aqdas from "./new Pages/Aqdas";
import AdminBriefPage from "./pages/adminBriefPage/AdminBriefPage";

import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/tos/TermsAndConditions";
import GoogleAnalyticsApp from "./constants/GoogleAnalyticsApp";
import { TrackGoogleAnalyticsEvent } from "./constants/Analytics";
import { CLIENT_ID } from "./constants/api";
import ResolutionCenter from "./pages/clientpages/resolutioncenter/ResolutionCenter";
import AdminOrderPage from "./pages/adminOrderPage/AdminOrderPage";
import Badges from "./views/ui/Badges.js";
import ClientBriefPage from "./pages/clientBriefPage/ClientBriefPage.jsx";
import AdminFullLayout from "./layouts/AdminFullLayout.js";
import AllPackages from "./pages/configure/AllPackages.jsx";
import AddNewPackage from "./pages/configure/AddNewPackage.jsx";
import AllIncludes from "./pages/configure/AllIncludes.jsx";
import AddNewInclude from "./pages/configure/AddNewInclude.jsx";
import EditInclude from "./pages/configure/EditInclude.jsx";
import Category from "./pages/category/Category.jsx";
import EditPackage from "./pages/configure/EditPackage.jsx";
const sampleCategory = "category";
const sampleAction = "Button_Clicked";

const App = () => {
  const role = localStorage.getItem("role");

  useEffect(() => {
    TrackGoogleAnalyticsEvent(sampleCategory, sampleAction);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/aqdas" element={<Aqdas />} />
          <Route path="/analytics" element={<GoogleAnalyticsApp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/portfolios" element={<AllPortfoliosPage />} />
          <Route path="/portfolio/:id" element={<SinglePortfolio />} />
          <Route path="/login" element={<ClientLogin />} />
          <Route path="/project-brief-submission" element={<ProjectBrief />} />
          <Route
            path="/client-auth"
            element={
              <Suspense fallback={<Loader />}>
                <FullLayout />
              </Suspense>
            }
          >
            <Route path="dashboard" element={<ClientDashboard />} />
            <Route path="inbox" element={<Inbox />} />
            <Route path="badges" element={<Badges />} />
            <Route path="orders" element={<Orders />} />
            <Route path="brief/:id" element={<Brief />} />
            <Route path="client-order-page/:id" element={<ClientOrderPage />} />
            <Route
              path="client-brief-page/:projectId/:briefId"
              element={<ClientBriefPage />}
            />
            <Route
              path="client-resolution-center/:id"
              element={<ResolutionCenter />}
            />
          </Route>
        </Routes>
        <Routes>
          <Route
            path="/admin"
            element={
              <GoogleOAuthProvider clientId={CLIENT_ID}>
                <Login />
              </GoogleOAuthProvider>
            }
          />

          <Route
            path="/auth"
            element={
              <Suspense fallback={<Loader />}>
                <AdminFullLayout />
              </Suspense>
            }
          >
            <Route
              path="dashboard"
              element={
                <GoogleOAuthProvider clientId={CLIENT_ID}>
                  <Dashboard />
                </GoogleOAuthProvider>
              }
            />
            <Route path="admin-order-page/:id" element={<AdminOrderPage />} />
            <Route
              path="admin-brief-page/:projectId/:briefId"
              element={<AdminBriefPage />}
            />
            <Route path="mailbox" element={<Mailbox />} />
            <Route path="orders" element={<AdminOrders />} />
            <Route path="department" element={<Department />} />
          
            <Route path="all-users" element={<AllUsers />} />
            <Route path="team" element={<Team />} />

            <Route path="workplace" element={<WorkPlace />} />

            <Route path="add-new-user" element={<AddNewUser />} />
            <Route path="all-portfolios" element={<AllPortfolios />} />
            <Route path="add-new-portfolio" element={<AddNewPortfolio />} />
            <Route path="all-users/edit-user/:id" element={<EditUser />} />
            <Route path="all-services" element={<AllServices />} />
            <Route path="add-new-service" element={<AddNewService />} />
            <Route path="settings" element={<Settings />} />
            <Route path="settings/brand" element={<Brand />} />
            <Route path="settings/faqs" element={<Faqs />} />
            <Route path="allPackages" element={<AllPackages />} />
            <Route path="allPackages/editPackage/:id" element={<EditPackage />} />
            <Route path="addNewPackage" element={<AddNewPackage />} />
            <Route path="allServices" element={<AllIncludes />} />
            <Route path="allServices/editService/:id" element={<EditInclude />} />
            <Route path="addNewService" element={<AddNewInclude />} />
            <Route path="category" element={<Category />} />
          </Route>
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

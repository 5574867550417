import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { format } from "date-fns";
import "./Mailbox.css";
import { CLIENT_API, MESSAGE_API, USER_API } from "../../constants/api";
import Picker from "emoji-picker-react";
function Mailbox() {
  const [socket, setSocket] = useState(null);
  const [projects, setProjects] = useState(null);
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const d_Id = localStorage.getItem("departmentId");
  const [clients, setClients] = useState(null);
  const [chat, setChat] = useState(null);
  const [selectedChatName, setSelectedChatName] = useState("");
  const myId = localStorage.getItem("userID");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [senderId, setSenderId] = useState("");
  const [checkActiveChat, setActiveChat] = useState("");
  const scrollableDivRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  var progress_initial = 0;
  const fileRef = useRef("");
  const handleAttachFile = () => {
    fileRef.current.click();
  };

  const handleImageChange = async (e) => {
    setFile(e.target.files[0]);
    setMessage(e.target.files[0].name);
    document.getElementById("admin-message-input").disabled = true;
  };
  const onEmojiClick = (e) => {
    console.log(e);

    setMessage((prev) => prev + e.emoji);
    setShowPicker(false);
  };
  const getProjects = async (sID, rID) => {
    if (role === "super-admin") {
      const check_user = await fetch(USER_API + "/users/list");
      const value = await check_user.json();
      if (check_user.ok) {
        const check_leads = await value.data.filter(
          (user) => user.role === "lead"
        );
        if (check_leads) {
          const find_lead = await check_leads.find((user) => user._id === sID);
          const check_role = await find_lead.role;
          if (check_role === "lead") {
            console.log(sID + " and " + check_role);
            const check_department = await find_lead.department;

            if (check_department) {
              console.log("check department is" + check_department);
              const response = await fetch(
                CLIENT_API + `/byDepartment/${check_department}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const json = await response.json();
              if (response.ok) {
                if (json.data == null) {
                } else {
                  const formattedData = json.data.map((record) => {
                    const createdAtDate = new Date(record.createdAt);
                    const day = createdAtDate.getDate();
                    const monthIndex = createdAtDate.getMonth();
                    const months = [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ];
                    const month = months[monthIndex];
                    const year = createdAtDate.getFullYear();
                    const formattedDate = `${day} ${month}, ${year}`;
                    return { ...record, formattedDate };
                  });
                  setProjects(formattedData);
                }
              }
            }
          }
        }
      } else {
      }
    } else {
      const response = await fetch(CLIENT_API + `/byDepartment/${d_Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        if (json.data == null) {
        } else {
          const formattedData = json.data.map((record) => {
            const createdAtDate = new Date(record.createdAt);
            const day = createdAtDate.getDate();
            const monthIndex = createdAtDate.getMonth();
            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            const month = months[monthIndex];
            const year = createdAtDate.getFullYear();
            const formattedDate = `${day} ${month}, ${year}`;
            return { ...record, formattedDate };
          });
          setProjects(formattedData);
        }
      } else {
      }
    }
  };

  const runProgress = () => {
    if (progress_initial == 0) {
      progress_initial = 1;
      var elem = document.getElementById("myBarAdmin");
      var width = 1;
      var id = setInterval(frame, 10);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          progress_initial = 0;
        } else {
          width++;
          elem.style.width = width + "%";
        }
      }
    }
  };

  // Socket.io
  useEffect(() => {
    const socket = socketIOClient("https://hype-x.ca:4000");
    setSocket(socket);

    socket.emit("storeSocketId", myId);

    socket.on("receive_message", (message) => {
      setChat((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const getClientChats = async () => {
      if (role === "super-admin") {
        const response = await fetch(MESSAGE_API + "/conversationIds");
        const clients = await response.json();
        console.log(clients.data);

        if (response.ok) {
          await setClients(clients.data);
        }
      } else {
        const response = await fetch(MESSAGE_API + "/sender/" + myId);
        const clients = await response.json();
        console.log(clients.data);

        if (response.ok) {
          await setClients(clients.data);
        }
      }
    };

    getClientChats();
  }, []);

  const getChat = async (sID, rID, rName, c_ID) => {
    let x = document.getElementById("myBarAdmin");
    if (x) {
      x.style.width = "0";
      progress_initial = 0;
    }

    const response = await fetch(MESSAGE_API + "/" + sID + "/" + rID);
    const myChat = await response.json();

    if (response.ok) {
      setChat(myChat.data);
      await setSelectedChatName(rName);
      await setReceiverId(sID);
      await setSenderId(rID);
      await setActiveChat(c_ID);
      getProjects(sID, rID);
    }
  };

  useEffect(() => {
    scrollableDivRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [chat]);

  const handleSend = async () => {
    document.getElementById("admin-message-input").placeholder = "sending...";
    setLoading(true);

    const formData = new FormData();

    formData.append("senderId", senderId);
    formData.append("receiverId", receiverId);

    if (file) {
      formData.append("file", file);
    } else {
      if (message.trim() !== "") {
        formData.append("message", message);
      }
    }

    try {
      const response = await fetch(MESSAGE_API + "/send", {
        method: "POST",
        body: formData,
      });
    } catch (error) {
    } finally {
      if (file) {
        await runProgress();
      }
      setMessage("");
      setFile("");

      getChat(receiverId, senderId, selectedChatName);
      document.getElementById("admin-message-input").disabled = false;
      setLoading(false);
      document.getElementById("admin-message-input").placeholder =
        "Type message...";
    }
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const createdAtDate = new Date(timestamp);
    const timeDifference = now - createdAtDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else {
      return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row clearfix">
        <div className="col-lg-12 p-0">
          <div className="card chat-app m-0">
            <div id="plist" className="people-list">
              <div className="people-list-header">
                <span className="people-list-header-search-message">
                  All Conversation
                </span>
                <img src="/search-normal.svg" alt="" />
              </div>
              <ul className="list-unstyled chat-list mt-2 mb-0">
                {clients &&
                  clients.map((client, index) =>
                    role === "super-admin" ? (
                      <li
                        onClick={() =>
                          getChat(
                            client.user1,
                            client.user2,
                            `${client.user1Name}, ${client.user2Name}`,
                            index
                          )
                        }
                        key={index}
                        className={`clearfix ${
                          index === checkActiveChat ? "active" : ""
                        }`}
                      >
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar1.png"
                          alt="avatar"
                        />
                        <div className="about">
                          <div className="name">
                            {client.user1Name}, {client.user2Name}
                          </div>
                          <div className="status">
                            {" "}
                            <i className="fas fa-circle offline" /> Conversation
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li
                        onClick={() =>
                          getChat(
                            client.senderId,
                            client.receiverId,
                            client.senderName,
                            client._id
                          )
                        }
                        key={client._id}
                        className={`clearfix ${
                          client._id === checkActiveChat ? "active" : ""
                        }`}
                      >
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar1.png"
                          alt="avatar"
                        />
                        <div className="about">
                          <div className="name">{client.senderName}</div>
                          <div className="status">
                            {" "}
                            <i className="fas fa-circle offline" />{" "}
                            {getTimeAgo(client.createdAt)}
                          </div>
                        </div>
                      </li>
                    )
                  )}
              </ul>
            </div>
            <div className="chat">
              {selectedChatName !== "" ? (
                <div className="chat-header shadow-lg clearfix">
                  <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar2.png"
                        alt="avatar"
                      />

                      <div className="chat-about">
                        <h6 className="m-b-0" style={{ color: "#ffffff" }}>
                          {selectedChatName}
                        </h6>
                        {/* <small style={{color: "#999999"}}>Last seen: 2 hours ago</small> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="chat-history">
                {projects && role === "lead" ? (
                  projects
                    .filter((project) => receiverId === project.userId)
                    .map((project) => (
                      <div className="mb-4 px-5 pb-5 pt-3 admin-inbox-top-project-box container-fluid">
                    <div className="row p-3">
                      <div className="col-md-12">
                        <Link
                          to={`/auth/admin-order-page/${project._id}`}
                          className="admin-inbox-top-project-button"
                        >
                          open order page
                        </Link>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4 admin-inbox-top-project-columns top-project-columns-left">
                        <p className="admin-inbox-top-project-paragraph">
                          Title
                        </p>
                        <h4 className="admin-inbox-top-project-column-heading">
                          {" "}
                          {project.currency.toUpperCase()} {project.price}
                        </h4>
                      </div>
                      <div className="col-4 admin-inbox-top-project-columns">
                        <p className="admin-inbox-top-project-paragraph">
                          Date
                        </p>
                        <h4 className="admin-inbox-top-project-column-heading">
                          {project.formattedDate}
                        </h4>
                      </div>
                      <div className="col-4 admin-inbox-top-project-columns top-project-columns-right">
                        <p className="admin-inbox-top-project-paragraph">
                          Package
                        </p>
                        <h4 className="admin-inbox-top-project-column-heading">
                          {project.packageName}
                        </h4>
                      </div>
                    </div>
                    <div className="row pt-5 w-100">
                      <div className="col-md-6">
                        <h4 className="admin-inbox-top-project-heading">
                          Title
                        </h4>
                        <p className="admin-inbox-top-project-paragraph">
                          {project.projectTitle}
                        </p>
                      </div>
                      <div className="col-md-6">
                      <h4 className="admin-inbox-top-project-heading">
                          Category
                        </h4>
                        <p className="admin-inbox-top-project-paragraph">
                          {project.projectCategory[0]}
                        </p>
                      </div>
                    </div>
                    <div className="row pt-2 w-100">
                      <div className="col-md-12">
                        <h4 className="admin-inbox-top-project-heading">
                          Description
                        </h4>
                        <p className="admin-inbox-top-project-paragraph">
                          {project.projectDescription}
                        </p>
                      </div>
                    </div>
                 
                  </div>
                    ))
                ) : projects &&
                projects
                  .filter((project) => senderId === project.userId)
                  .map((project) => (

                    <div className="mb-4 px-5 pb-5 pt-3 admin-inbox-top-project-box container-fluid">
                    <div className="row p-3">
                      <div className="col-md-12">
                        <Link
                          to={`/auth/admin-order-page/${project._id}`}
                          className="admin-inbox-top-project-button"
                        >
                          open order page
                        </Link>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4 admin-inbox-top-project-columns top-project-columns-left">
                        <p className="admin-inbox-top-project-paragraph">
                          Title
                        </p>
                        <h4 className="admin-inbox-top-project-column-heading">
                          {" "}
                          ${project.price / 100}
                        </h4>
                      </div>
                      <div className="col-4 admin-inbox-top-project-columns">
                        <p className="admin-inbox-top-project-paragraph">
                          Date
                        </p>
                        <h4 className="admin-inbox-top-project-column-heading">
                          {project.formattedDate}
                        </h4>
                      </div>
                      <div className="col-4 admin-inbox-top-project-columns top-project-columns-right">
                        <p className="admin-inbox-top-project-paragraph">
                          Package
                        </p>
                        <h4 className="admin-inbox-top-project-column-heading">
                          {project.packageName}
                        </h4>
                      </div>
                    </div>
                    <div className="row pt-5 w-100">
                      <div className="col-md-6">
                        <h4 className="admin-inbox-top-project-heading">
                          Title
                        </h4>
                        <p className="admin-inbox-top-project-paragraph">
                          {project.projectTitle}
                        </p>
                      </div>
                      <div className="col-md-6">
                      <h4 className="admin-inbox-top-project-heading">
                          Category
                        </h4>
                        <p className="admin-inbox-top-project-paragraph">
                          {project.projectCategory[0]}
                        </p>
                      </div>
                    </div>
                    <div className="row pt-2 w-100">
                      <div className="col-md-12">
                        <h4 className="admin-inbox-top-project-heading">
                          Description
                        </h4>
                        <p className="admin-inbox-top-project-paragraph">
                          {project.projectDescription}
                        </p>
                      </div>
                    </div>
                 
                  </div>
 ))}
                <ul className="mb-0" ref={scrollableDivRef}>
                  {selectedChatName == "" ? (
                    <div className="select-conversation">
                      <img
                        src="/sidebar-logo.png"
                        style={{ marginLeft: "10px" }}
                        alt=""
                      />
                      <p className="select-conversation-heading">
                        Pick up where you left off
                      </p>
                      <p className="select-conversation-description">
                        Select a conversation and chat away.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {chat &&
                    chat.map((data) => (
                      <li className="clearfix">
                        <div
                          className={`${
                            data.senderId === myId || data.senderRole === "lead"
                              ? "message-data text-end"
                              : "message-data"
                          }`}
                        >
                          {data.senderId === myId ||
                          data.senderRole === "lead" ? (
                            <>
                              <span
                                className="message-data-time"
                                style={{
                                  color: "#ffffff",
                                  fontSize: "13px",
                                }}
                              >
                                {data.senderName}{" "}
                                <span className="text-emphasis">
                                  (
                                  {format(
                                    new Date(data.createdAt),
                                    "dd MMM, yyyy hh:mm a"
                                  )}
                                  )
                                </span>
                              </span>
                              <img
                                src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                alt="avatar"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src="https://bootdey.com/img/Content/avatar/avatar6.png"
                                alt="avatar"
                              />
                              <span
                                className="message-data-time"
                                style={{
                                  color: "#ffffff",
                                  fontSize: "13px",
                                }}
                              >
                                {data.senderName}{" "}
                                <span className="text-emphasis">
                                  (
                                  {format(
                                    new Date(data.createdAt),
                                    "dd MMM, yyyy hh:mm a"
                                  )}
                                  )
                                </span>
                              </span>
                            </>
                          )}
                        </div>
                        <div
                          className={`${
                            data.senderId === myId || data.senderRole === "lead"
                              ? "message other-message float-right"
                              : "message my-message"
                          }`}
                        >
                          {" "}
                          {data.message ? <>{data.message}</> : <></>}
                          {data.file.length !== 0 ? (
                            <>
                              <img
                                style={{ maxWidth: "300px" }}
                                src={data.file[0].secure_url}
                                alt=""
                              />
                              <br />
                              <a
                                href={data.file[0].secure_url}
                                target="_blank"
                                download={"File"}
                              >
                                Download
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </li>
                    ))}
                </ul>
                {showPicker && (
                  <div
                    className="float-right"
                    style={{
                      zIndex: "7",
                      position: "absolute",
                      right: "10px",
                      bottom: "50px",
                    }}
                  >
                    <Picker
                      pickerStyle={{
                        width: "100%",
                        opacity: "0.1",
                      }}
                      onEmojiClick={onEmojiClick}
                    />
                  </div>
                )}
              </div>
              {selectedChatName !== "" ? (
                <div
                  className={`chat-message clearfix ${
                    role === "super-admin" ? "d-none" : ""
                  }`}
                >
                  <div className="input-group mb-0">
                    <div id="myProgress">
                      <div id="myBarAdmin"></div>
                    </div>
                    <input
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSend();
                        }
                      }}
                      id="admin-message-input"
                      onChange={(e) => setMessage(e.target.value)}
                      type="text"
                      value={message}
                      style={{
                        height: "50px",
                        borderRadius: "0",
                        fontSize: "13x!important",
                      }}
                      className="form-control"
                      placeholder="Type message..."
                    />
                    <div className="chat-message-button-group">
                      <span
                        onClick={() => setShowPicker((val) => !val)}
                        className="chat-message-emoji-button"
                      >
                        <img src="/chat-emoji.svg" alt="" />
                      </span>
                      <span className="chat-message-file-button">
                        <img
                          onClick={handleAttachFile}
                          src="/chat-file.svg"
                          alt=""
                        />
                        <input
                          ref={fileRef}
                          type="file"
                          onChange={handleImageChange}
                          className="form-control-file d-none"
                          id="exampleFormControlFile1"
                        />
                      </span>

                      {loading ? (
                        <>
                          <span className="chat-message-sending-button">
                            <b>Sending...</b>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            onClick={handleSend}
                            className="chat-message-send-button"
                          >
                            <b>Send</b>&nbsp;{" "}
                            <img src="/bxl_telegram.svg" alt="" />
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`${
                selectedChatName == "" ? "people-profile-2" : "people-profile"
              }`}
            >
              <div
                className={`${
                  selectedChatName == "" ? "d-none" : "people-profile-image"
                }`}
              >
                <img
                  src="/scrolling-1.png"
                  className="img-fluid"
                  style={{ borderRadius: "50%" }}
                  alt=""
                />
              </div>
              <div
                className={`${
                  selectedChatName == "" ? "d-none" : "people-profile-name"
                }`}
              >
                <span className="text-white">{selectedChatName}</span>
              </div>
              {projects && role === "lead"
                ? projects
                    .filter((project) => receiverId === project.userId)
                    .map((project) => (
                      <div
                        className={`${
                          selectedChatName == ""
                            ? "d-none"
                            : "people-profile-package"
                        } ${
                          project.packageName === "Essential" ||
                          project.packageName === "E-commerce"
                            ? "essen-package"
                            : ""
                        } ${
                          project.packageName === "Premium"
                            ? "prem-package"
                            : "oth-package"
                        }`}
                      >
                        <span className="people-profile-package-label">
                          Package
                        </span>
                        <span className="people-profile-package-name">
                          {project.packageName}
                        </span>
                        <Link
                          className="people-profile-package-cta btn btn-sm"
                          to={`/auth/admin-order-page/${project._id}`}
                        >
                          Track
                        </Link>
                      </div>
                    ))
                : projects &&
                  projects
                    .filter((project) => senderId === project.userId)
                    .map((project) => (
                      <div
                        className={`${
                          selectedChatName == ""
                            ? "d-none"
                            : "people-profile-package"
                        } ${
                          project.packageName === "Essential" ||
                          project.packageName === "E-commerce"
                            ? "essen-package"
                            : ""
                        } ${
                          project.packageName === "Premium"
                            ? "prem-package"
                            : "oth-package"
                        }`}
                      >
                        <span className="people-profile-package-label">
                          Package
                        </span>
                        <span className="people-profile-package-name">
                          {project.packageName}
                        </span>
                        <Link
                          className="people-profile-package-cta btn btn-sm"
                          to={`/auth/admin-order-page/${project._id}`}
                        >
                          Track
                        </Link>
                      </div>
                    ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mailbox;

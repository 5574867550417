import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./Category.css";
import Swal from "sweetalert2";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";

import SettingsIcon from "@mui/icons-material/Settings";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { CATEGORY_API, DEPARTMENT_API } from "../../constants/api";

function Category() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = useState("");
  const [selected_id, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [myError, setMyError] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    
    },
   
    {
      name: "Action",
      width: "150px",
      cell: (row) => (
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, row._id)}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <SettingsIcon style={{ color: "#828282", fontSize: "16px" }} />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom", // Change to bottom
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem>
              <Button
                onClick={() => handleDelete(selected_id)}
                style={{
                  color: "#2C2C2C",
                  fontFamily: "Source Sans Pro",
                  fontSize: "14px",
                }}
                startIcon={
                  <DeleteIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Delete
              </Button>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
              <Button
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
                style={{
                  color: "#2C2C2C",
                  fontFamily: "Source Sans Pro",
                  fontSize: "14px",
                }}
                startIcon={
                  <CancelIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
              >
                Suspend
              </Button>
            </MenuItem> */}
          </Menu>
        </div>
      ),
    },
  ];

  const [data1, setData] = useState(null);

  const [records, setRecords] = useState([]);

  const [update, setUpdate] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {

      try{

        setLoading(true)
        const response = await fetch(CATEGORY_API + "/list");
        const json = await response.json();
  
        if (response.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            setData(json.data);
            setRecords(json.data);
            console.log(myError);
          }
        } else {
          setRecords([]);
        }

      }catch(error){

      }finally{
        setLoading(false)
      }
  
    };

    fetchUsers();
  }, [update, myError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Creating New Category...");
    const department = {
      name,
    };

    try {
      const response = await fetch(CATEGORY_API + "/create", {
        method: "POST",
        body: JSON.stringify(department),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.error);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("Category Created successfully!");
        setName("");
        setMyError(Math.random());
      }
    } catch (error) {
      console.error("Error creating Category:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while creating .");
    } finally {
      setLoading(false);
    }
  };

  const setFilter = (value) => {
    const result = data1.filter((record) => {
      return record.name.toLowerCase().includes(value.toLowerCase());
    });

    setRecords(result);
  };

  const handleDelete = (myId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(CATEGORY_API + "/delete/" + d_id, {
              method: "PATCH",
            });
            setUpdate(d_id);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Category has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Category is safe :)",
            icon: "error",
          });
        }
      });
  };



  return (
    <>
      {/* <form onSubmit={handleSubmit}>
        <div className="form-row px-2 pt-4">
          <div className="form-group col-md-3 pl-2">
            <input
              type="text"
              value={departName}
              onChange={(e) => setDepartName(e.target.value)}
              placeholder="Department Name"
              className="all-users-search-bar w-100"
              id="inputEmail4"
            />
          </div>
          <div className="col-md-3">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <button type="submit" className="btn add-new-department-button">
                Add Department
              </button>
            )}
          </div>
          <div className="form-group col-md-6 d-flex justify-content-end pr-2">
            <input
              className={`all-users-search-bar ${
                data1 == null ? "d-none" : ""
              } `}
              placeholder="Search"
              onChange={(e) => setFilter(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </form>
      <DataTable
        customStyles={tableHeaderStyle}
        columns={columns}
        data={records}
        defaultSortField="_id"
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        selectableRows
        selectableRowsHighlight
        highlightOnHover
      /> */}
      <div className="container-fluid">
        <h2 className="mb-5">Categories</h2>

        <div className="header-body">
          <div className="row">
            <div className="col-md-8">
              <form className="my-2" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-8">
                    <input
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter category name"
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 col-4">
                    <button
                      type="submit"
                      className="btn btn-sm"
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Add
                    </button>
                  </div>
                </div>

                {/* <div className="form-row px-2 pt-4">
          <div className="form-group col-md-6 pl-2">
            <input
              type="text"
              value={departName}
              onChange={(e) => setDepartName(e.target.value)}
              placeholder="Department Name"
              className=""
              id="inputEmail4"
            />
          </div>
          <div className="col-md-6">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <button type="submit" className="btn">
                Add Department
              </button>
            )}
          </div>
     
        </div> */}
              </form>
            </div>
            <div className="col-md-4 col-sm-12">
              <form action="">
                <input
                  className={`form-control ${data1 == null ? "d-none" : ""} `}
                  placeholder="Search"
                  onChange={(e) => setFilter(e.target.value)}
                  type="text"
                />
              </form>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={records}
              pagination
              progressPending={loading}
              progressComponent={
                <div className="text-center my-2 bg-white">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
              // Add custom styles or classes here using Bootstrap classes
              className="table table-striped table-hover"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;

import React, { useEffect, useState } from "react";
import "./Aqdas.css";

function Aqdas() {

  const [toogle, setToogle] = useState(false)

  const switchToogle = () => {
    if(toogle === true){
      setToogle(false)
    }else{
      setToogle(true)
    }
  }
  return (
    <div className="custom-wrapper">
    <aside id="custom-sidebar" className={`${toogle === true? "expand" : ""}`}>
      <div className="d-flex">
        <button onClick={switchToogle} className="custom-toggle-btn" type="button">
          <i className="lni lni-grid-alt" />
        </button>
        <div className="custom-sidebar-logo">
          <a href="#">HypeX</a>
        </div>
      </div>
      <ul className="custom-sidebar-nav">
        <li className="custom-sidebar-item">
          <a href="#" className="custom-sidebar-link">
            <i className="lni lni-user" />
            <span>Profile</span>
          </a>
        </li>
        <li className="custom-sidebar-item">
          <a href="#" className="custom-sidebar-link">
            <i className="lni lni-agenda" />
            <span>Task</span>
          </a>
        </li>
        <li className="custom-sidebar-item">
          <a href="#" className="custom-sidebar-link collapsed has-dropdown" data-bs-toggle="collapse" data-bs-target="#auth" aria-expanded="false" aria-controls="auth">
            <i className="lni lni-protection" />
            <span>Auth</span>
          </a>
          <ul id="auth" className="custom-sidebar-dropdown list-unstyled collapse" data-bs-parent="#custom-sidebar">
            <li className="custom-sidebar-item">
              <a href="#" className="custom-sidebar-link">Login</a>
            </li>
            <li className="custom-sidebar-item">
              <a href="#" className="custom-sidebar-link">Register</a>
            </li>
          </ul>
        </li>
        <li className="custom-sidebar-item">
          <a href="#" className="custom-sidebar-link collapsed has-dropdown" data-bs-toggle="collapse" data-bs-target="#multi" aria-expanded="false" aria-controls="multi">
            <i className="lni lni-layout" />
            <span>Multi Level</span>
          </a>
          <ul id="multi" className="custom-sidebar-dropdown list-unstyled collapse" data-bs-parent="#custom-sidebar">
            <li className="custom-sidebar-item">
              <a href="#" className="custom-sidebar-link collapsed" data-bs-toggle="collapse" data-bs-target="#multi-two" aria-expanded="false" aria-controls="multi-two">
                Two Links
              </a>
              <ul id="multi-two" className="custom-sidebar-dropdown list-unstyled collapse">
                <li className="custom-sidebar-item">
                  <a href="#" className="custom-sidebar-link">Link 1</a>
                </li>
                <li className="custom-sidebar-item">
                  <a href="#" className="custom-sidebar-link">Link 2</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="custom-sidebar-item">
          <a href="#" className="custom-sidebar-link">
            <i className="lni lni-popup" />
            <span>Notification</span>
          </a>
        </li>
        <li className="custom-sidebar-item">
          <a href="#" className="custom-sidebar-link">
            <i className="lni lni-cog" />
            <span>Setting</span>
          </a>
        </li>
      </ul>
      <div className="custom-sidebar-footer">
        <a href="#" className="custom-sidebar-link sidebar-footer-link">
          <i className="lni lni-exit" />
          <span>Logout</span>
        </a>
      </div>
    </aside>
    <div className="custom-main p-3">
      <div className="text-center">
        <h1>
          Sidebar Bootstrap 5
        </h1>
      </div>
    </div>
  </div>
  

  );
}

export default Aqdas;

import React from "react";
import { useState, useEffect, useRef } from "react";
import "./Brand.css";
import Swal from "sweetalert2";

import DeleteIcon from "@mui/icons-material/Delete";

import { toast } from "react-toastify";
import { BRAND_API } from "../../../constants/api";

function Brand() {
  const token = localStorage.getItem("token");
  const [file, setFile] = useState("");
  const imageRef = useRef("");
  const [loading, setLoading] = useState(false);
  const [myError, setMyError] = useState("");


  const [data1, setData] = useState(null);


  const [update, setUpdate] = useState("");

  useEffect(() => {
    const Brands = async () => {
      const response = await fetch(BRAND_API + "/list");
      const json = await response.json();

      if (response.ok) {
        if (json.data == null) {
       
        } else {
          setData(json.data);
        
          console.log(myError);
        }
      } else {

      }
    };

    Brands();
  }, [update, myError]);

  const handleUpload = async (myFile) => {
    const loadingToast = toast.loading("Uploading Image...");

    try {
      const formData = new FormData();
      formData.append("image", myFile);

      const response = await fetch(BRAND_API + "/upload", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          toast.dismiss(loadingToast);
          toast.success("Media uploaded successfully!");
          setMyError(Math.random());
        } else {
          toast.dismiss(loadingToast);
          toast.error("Failed to upload media.");
        }
      } else {
        toast.dismiss(loadingToast);
        toast.error("Server error.");
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Error uploading media:", error);
      toast.error("Error uploading media.");
    } finally {
    }
  };

  const handleImageClick = () => {
    imageRef.current.click();
  };
  const handleImageChange = (e) => {
    const myFile = e.target.files[0];
    setFile(e.target.files[0]);

    handleUpload(myFile);
  };

  const handleDelete = (myId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-danger btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(BRAND_API + "/delete/" + d_id, {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setUpdate(d_id);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Logo has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Logo is safe :)",
            icon: "error",
          });
        }
      });
  };

  return (
    <>


      <div className="container-fluid">
        <h2 className="mb-5">Brand Logos</h2>

        <div className="header-body">
     
        <div className="form-row px-2 pt-2 d-flex justify-content-center w-100">
          <div className="col-md-3 p-2 text-center">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                <input
                  type="file"
                  onChange={handleImageChange}
                  ref={imageRef}
                  style={{ display: "none" }}
                />
                <a
                  onClick={handleImageClick}
                  type="submit"
                  className="btn"
                  style={{backgroundColor: "black", color: "white"}}
                >
                  Upload Logo
                </a>
              </>
            )} 
          </div>
        </div>

        <div className="row d-flex justify-content-center py-4">
          {data1 &&
            data1.map((logo) => (
              <div key={logo._id} className="col-sm-12 col-lg-3 col-md-4 d-flex justify-content-center logo-column-brand">
                <div className="card d-flex justify-content-center align-items-center p-5 m-3" style={{ borderRadius: "50%",  border: "2px solid white"}}>
                  <img style={{height: "100px", width: "100px"}} src={logo.secure_url} className="card-img-top" alt="..." />
                  <span onClick={()=>handleDelete(logo._id)} className="delete-logo-brand"> <DeleteIcon style={{fontSize: "20px"}}/> </span>
                </div>
              </div>
            ))}
        </div>
        </div>
      </div>
    </>
  );
}

export default Brand;

import React, {useState, useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CLIENT_API } from "../../../constants/api";

import { format } from "date-fns";
function ResolutionCenter() {
    const { id } = useParams();
    const [project, setProject] = useState([]);
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
  
    const navigate = useNavigate()
  
    useEffect(() => {
  
      
      const getProject = async () => {
        const response = await fetch(CLIENT_API + "/single/" + id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const json = await response.json();
        if (response.ok) {
          setProject(json.data ? [json.data] : []);
        } else {
          // Handle error response
          console.error("Error fetching project:", json);
        }
      };
  
      getProject();
    }, [id, token]);
  return (
    <>
      {project.map((proj) => (
      <div className="container-fluid p-5">
        <h1 className="text-white">Resolution Center</h1>
        <p className="text-white">
          This is where you can try to resolve order issues.
        </p>
        <div className="row">
          <div className="col-md-8">
            <div className="row mt-4">
              <div className="col-md-6">
                <h4 className="text-white">Ask to cancel the order</h4>
                <p className="text-white">
                  You can ask to cancel any order, but partial refunds are only
                  available for eligible orders.
                </p>
              </div>
            </div>
            <form>
              <div className="form-row mt-4">
                <div className="form-group col-md-6">
                  <label className="text-white" htmlFor="inputState">
                    Choose the reason for your cancellation order
                  </label>
                  <select id="inputState" className="form-control">
                    <option selected>Select...</option>
                    <option>...</option>
                  </select>
                </div>
              </div>
              <div className="form-group mt-4">
                <label className="text-white" htmlFor="inputState">
                  Let us know why you want to cancel this order
                </label>
                <textarea
                  type="text"
                  rows={15}
                  className="form-control"
                  id="inputAddress"
                  placeholder="Add a personal note to explain why..."
                />
              </div>

              <button type="submit" className="btn btn-secondary float-right">
                Submit
              </button>
            </form>
          </div>
          <div className="col-md-4">
          <div className="container-fluid">
          <div className="row d-flex justify-content-center">
          <div className="col-10">
                  <div className="card activity-card w-100 text-white">
                    <div className="custom-title text-white">Order Details <img className="image-fluid mt-2" width="100%" src={proj.file[0].secure_url} alt="" /></div>
                    <div className="info">
                      <div className="row">
                        <div className="col-7">
                          <span id="heading">Date</span>
                          <br />
                          <span id="details">
                            {" "}
                            {format(new Date(proj.createdAt), "dd MMM, yyyy")}
                          </span>
                        </div>
                        <div className="col-5 pull-right" style={{fontSize: "14px"}}>
                          <span id="heading">Order No.</span>
                          <br />
                          <span id="details">{proj._id}</span>
                        </div>
                      </div>
                    </div>
                    <div className="pricing">
                      <div className="row">
                        <div className="col-9">
                          <span id="name">{proj.projectTitle}</span>
                        </div>
                        <div className="col-3 column-3">
                          <span
                            style={{ textTransform: "uppercase", fontSize: "14px" }}
                            id="price"
                          >
                            {proj.currency} {proj.price / 100}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="total">
                      <div className="row">
                        <div className="col-9" />
                        <div className="col-3 column-3 text-white">
                          <strong style={{ textTransform: "uppercase", fontSize: "14px" }}>
                            {proj.currency} {proj.price / 100}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
        ))}
    </>
  );
}

export default ResolutionCenter;

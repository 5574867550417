import React from "react";
import { useEffect, useState, useContext } from "react";
import "./SectionOne.css";
import Marquee from "react-fast-marquee";
import { BRAND_API } from "../../../constants/api";
import { NavContext } from "../../LandingPage";

const touchThreshold = 150;
function SectionOne() {
  const [brandLogos, setBrandLogos] = useState(null);
  const { index, setIndex } = useContext(NavContext);
  useEffect(() => {
    const getBrandLogos = async () => {
      const response = await fetch(BRAND_API + "/list");
      const logos = await response.json();

      if (response.ok) {
        setBrandLogos(logos.data);
      } else {
        alert("Brands logos not found");
      }
    };

    getBrandLogos();
  }, []);
  let touchStartY = 0;

  const handleWheel = async (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if (event.deltaY > 0) {
        var muhammadAqdas = document.getElementById("muhammad-aqdas")
        var muhammadLogo = document.getElementById("muhammad-logo")
        if(muhammadAqdas){
        muhammadAqdas.style.animationName = "muhammad-ani"
        muhammadAqdas.style.animationDuration = "0.5s"
        muhammadLogo.style.opacity = "0"
        }
    
        setTimeout(function() {
          setIndex(1)
          setTimeout(function() {
            if (muhammadAqdas) {
                muhammadAqdas.style.animationName = ""; // Reset the animation name
                 muhammadLogo.style.opacity = "1"
            }
        }, 300); // Adjust the delay as needed
        }, 300);
      } else {
        setIndex(0);
      }
    }
  };

  const handleTouchStart = (event) => {
    const target = event.currentTarget;
    const startY = event.touches[0].clientY;
    //  alert(startY)
    touchStartY = startY;
  };

  const handleTouchMove = async (event) => {
    let x = touchStartY;
    if (touchStartY !== 0) {
      const deltaY = event.touches[0].clientY - touchStartY;
      if (Math.abs(deltaY) > touchThreshold) {
        const direction = deltaY > 0 ? 1 : -1;
        if (direction === 1) {
          setIndex(0);
        } else {

  
          setIndex(1);
        }
        touchStartY = 0;
      }
    }
  };

  const handleTouchEnd = () => {
    touchStartY = 0;
  };

  useEffect(() => {
    const sectionOneElement = document.getElementById("section-one");
    if (sectionOneElement) {
      sectionOneElement.addEventListener("wheel", handleWheel);
      sectionOneElement.addEventListener("touchstart", handleTouchStart);
      sectionOneElement.addEventListener("touchmove", handleTouchMove);
      sectionOneElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      console.log("removed 3");
      if (sectionOneElement) {
        sectionOneElement.removeEventListener("wheel", handleWheel);
        sectionOneElement.removeEventListener("touchstart", handleTouchStart);
        sectionOneElement.removeEventListener("touchmove", handleTouchMove);
        sectionOneElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [index]);
  return (
    <div id="section-one" className="section-one d-flex position-relative">
      {/* <div className="position-absolute top-0 start-0 david-div-one"> */}
      <img id="muhammad-logo" src="/media/Frame.png" className="david-logo-one-desktop" alt="" /> 
      {/* </div> */}
    
      <div className="container align-self-center"> 
        <div className="row d-flex justify-content-center hide-show-animation-class">
          <div className="col-md-8 d-flex justify-content-between">
            <p className="align-self-center section-one-desc">
              CONTENT SUBSCRIPTIONS FOR EVERYONE WITH <br />
              UNLIMITED DESIGNS, VIDEOS AND COPYWRITING
            </p>
            <img
              className="image-fluid section-one-logo"
              src="/media/hypeX-logo-dark.svg"
              alt=""
            />
          </div>
        </div>
        <div id="muhammad-aqdas" className="row mt-2 d-flex justify-content-center come-in-animation-class">
          <div className="col-md-8"> 
            <h1 className="text-center section-one-heading">
              THE NEW WAY <br />
              TO <span className="hype-red">CREATE</span>
              <br />
              <span className="hype-blue">CONTENT</span>
            </h1>
          </div>
        </div>
        <div className="row my-2 d-flex justify-content-center hide-show-animation-class">
          <div className="col-md-8 text-center">
            <button onClick={()=>setIndex(8)} className="btn section-one-cta">See Plans</button>
          </div>
        </div>
        <div className="row mt-4 d-flex justify-content-center hide-show-animation-class">
          <div className="col-md-8 px-2">
            <Marquee>
              {brandLogos &&
                brandLogos.map((brandLogo) => (
                  <div className="d-flex flex-row">
                    <div className="mx-2">
                      <img
                        className="section-one-marquee-image"
                        src={brandLogo.secure_url}
                        alt=""
                      />
                    </div>
                    <div className="mx-2">
                      <img
                        className="section-one-marquee-dot"
                        src="/media/marqee-dot.svg"
                        alt=""
                      />
                    </div>
                  </div>
                ))}
            </Marquee>
          </div>
        </div>
        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-md-8 text-center d-flex justify-content-center">
            <img src="/media/DavidOutline-mobile.svg" className="section-one-mobile-david-desktop img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOne;

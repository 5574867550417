import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./AllUsers.css";
import Swal from "sweetalert2";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import SettingsIcon from "@mui/icons-material/Settings";
import CancelIcon from "@mui/icons-material/Cancel";
import { DEPARTMENT_API, USER_API } from "../../../constants/api";

function AllUsers() {
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected_id, setSelectedId] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    console.log("Clicked row ID:", id);
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => {
        const depart =
          allDepartments &&
          allDepartments.find((dep) => dep._id === row.department);

        return <>{depart ? depart.departName : "Unknown Department"}</>;
      },

      grow: 1.2,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      grow: 1.5,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, row._id)}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <SettingsIcon style={{ color: "#828282", fontSize: "16px" }} />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom", // Change to bottom
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleClose}>
              <Button
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <EditIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <Link
                  to={`edit-user/${selected_id}`}
                  style={{ color: "#2C2C2C", textDecoration: "none" }}
                >
                  Edit
                </Link>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                onClick={() => handleDelete(selected_id)}
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <DeleteIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Delete
              </Button>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
              <Button
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <CancelIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
              >
                Suspend
              </Button>
            </MenuItem> */}
          </Menu>
        </div>
      ),
    },
  ];

  const [data1, setData] = useState(null);
  const [allDepartments, setAllDepartments] = useState(null);

  const [records, setRecords] = useState([]);

  const [update, setUpdate] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try{
        setLoading(true)
        const response = await fetch(USER_API + "/users/list");
        const json = await response.json();
  
        if (response.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            console.log(json.data);
            // setData(json.data.filter((d) => d.role !== "super-admin"));
            // setRecords(json.data.filter((d) => d.role !== "super-admin"));
            setData(json.data);
            setRecords(json.data);
          }
        } else {
          setRecords([]);
        }

      }catch(error){

      }finally{
        setLoading(false)
      }
 
    };

    const fetchDepartments = async () => {
      const response = await fetch(DEPARTMENT_API + "/list");
      const json = await response.json();
      if (response.ok) {
        setAllDepartments(json.data);
      }
    };
    fetchUsers();
    fetchDepartments();
  }, [update]);

  const setFilter = (value) => {
    const result = data1.filter((record) => {
      if (record.firstname == null || record.firstname == null) {
        return (
          // record._id.toString().includes(value.toLowerCase()) ||
          record.username.toLowerCase().includes(value.toLowerCase()) ||
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.role.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        return (
          // record._id.toString().includes(value.toLowerCase()) ||
          record.username.toLowerCase().includes(value.toLowerCase()) ||
          record.firstname.toLowerCase().includes(value.toLowerCase()) ||
          record.lastname.toLowerCase().includes(value.toLowerCase()) ||
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.role.toLowerCase().includes(value.toLowerCase())
        );
      }
    });

    setRecords(result);
  };

  const handleDelete = (myId) => {
    console.log(myId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-danger btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(USER_API + "/delete/" + d_id, {
              method: "DELETE",
            });
            setUpdate(d_id);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "User is safe :)",
            icon: "error",
          });
        }
      });
  };

  const tableHeaderStyle = {
    headRow: {
      style: {
        backgroundColor: "#1F2123",
      },
    },
    headCells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        backgroundColor: "#1F2123",
        color: "#ffffff",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    paginationButton: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    table: {
      style: {
        padding: "10px",
        backgroundColor: "#1F2123",
      },
    },
  };

  return (
    <div className="container-fluid">
      <h2 className="mb-5">All Users</h2>

      <div className="header-body">
        <div className="row mb-1 d-flex justify-content-end">
          <div className="col-md-4 col-sm-6">
            <form action="">
              <input
                className={`form-control ${data1 == null ? "d-none" : ""} `}
                placeholder="Search"
                onChange={(e) => setFilter(e.target.value)}
                type="text"
              />
            </form>
          </div>
        </div>
        <div className="row">
          <DataTable
            columns={columns}
            data={records}
            pagination
            progressPending={loading}
              progressComponent={
                <div className="text-center my-2 bg-white">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
            // Add custom styles or classes here using Bootstrap classes
            className="table table-striped table-hover"
          />
        </div>
      </div>
    </div>
  );
}

export default AllUsers;

import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./AllPortfolios.css";
import Swal from "sweetalert2";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SettingsIcon from "@mui/icons-material/Settings";
import CancelIcon from "@mui/icons-material/Cancel";
import { PORTFOLIO_API, DEPARTMENT_API } from "../../../constants/api";

function AllPortfolios() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected_id, setSelectedId] = useState("");
  const token = localStorage.getItem("token");
  const open = Boolean(anchorEl);
  const [departments, setDepartments] = useState(null)
  const handleClick = (event, id) => {
    console.log("Clicked row ID:", id);
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <img
          className="image-fluid"
          height={35}
          src={row.featuredImage[0].secure_url}
          alt=""
        />
      ),
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description.slice(0, 20)+"...",
      sortable: true,
    },

   {
    name: "Category",
    selector: (row) => {
      if(departments !== null){
        const department = departments.find(department => department._id === row.departmentId);
        return department ? department.departName : "Unknown";
      }
      
    },
    sortable: true,
   },
   {
    name: "Date Added",
    selector: (row) => {
      const createdAtDate = new Date(row.createdAt);
      const day = createdAtDate.getDate();
      const monthIndex = createdAtDate.getMonth();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = months[monthIndex];
      const year = createdAtDate.getFullYear();
      const formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    },
    sortable: true,
  },
  {
    name: "Featured",
    selector: (row) => (
      row.isFeatured? <CheckCircleOutlineIcon style={{ color: "#36E93C", fontSize: "18px" }} /> : <CancelOutlinedIcon style={{ color: "red", fontSize: "18px" }} />
    ),
    sortable: true,
  },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, row._id)}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <SettingsIcon style={{ color: "#828282", fontSize: "16px" }} />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom", // Change to bottom
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
           
          >
            <MenuItem onClick={handleClose}>
              
              <Button
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd", 
                  fontSize: "14px",
                  
                }} 

                startIcon={
                  <EditIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <Link
                  to="/auth/all-portfolios"
                  style={{ color: "#2C2C2C", textDecoration: "none" }}
                >
                  Edit
                </Link>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                onClick={() => handleDelete(selected_id)}
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <DeleteIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Delete
              </Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Button
              
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <CancelIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
              >
                Suspend
              </Button>
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const [data1, setData] = useState(null);

  const [records, setRecords] = useState([]);

  const [update, setUpdate] = useState("");

  useEffect(() => {
    const fetchPortfolios = async () => {
      const response = await fetch(PORTFOLIO_API + "/list");
      const json = await response.json();

      if (response.ok) {
        if (json.data == null) {
          setRecords([]);
        } else {
          const formattedData = json.data.map((record) => {
            const createdAtDate = new Date(record.createdAt);
            const day = createdAtDate.getDate();
            const monthIndex = createdAtDate.getMonth();
            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            const month = months[monthIndex];
            const year = createdAtDate.getFullYear();
            const formattedDate = `${day} ${month}, ${year}`;
            return { ...record, formattedDate };
          });
          setData(formattedData);
          setRecords(formattedData);
        }
      } else {
        setRecords([]);
      }
    };

    fetchPortfolios();
   
  }, [update]);

  useEffect(() => {

    const fetchDepartments = async () => {
      const response = await fetch(DEPARTMENT_API + "/list");
      const json = await response.json();
 
      if (response.ok) {
        setDepartments(json.data)
      } else {
        console.log("error getting departments")
      }
    };

    fetchDepartments();
  }, []);

  const setFilter = (value) => {
    const result = data1.filter((record) => {
      const department = departments.find(dept => dept._id === record.departmentId);
      const departmentName = department ? department.departName.toLowerCase() : "";
        return (
          record.title.toLowerCase().includes(value.toLowerCase()) ||
          record.description.toLowerCase().includes(value.toLowerCase()) ||
          record.formattedDate
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          departmentName.includes(value.toLowerCase())
        );

    });

    setRecords(result);
  };

  const handleDelete = (myId) => {
    console.log(myId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ml-2",
        cancelButton: "btn btn-danger btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(PORTFOLIO_API + "/delete/" + d_id, {
              method: "PATCH",
              headers: {
                 Authorization: `Bearer ${token}`
              }
            });
            setUpdate(d_id);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "User is safe :)",
            icon: "error",
          });
        }
      });
  };

  const tableHeaderStyle = {
    headRow: {
      style: {
        backgroundColor: "#1F2123",
      },
    },
    headCells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        fontSize: "14px",
      },
    },
    rows: { 
      style: {
        backgroundColor: "#1F2123",
        color: "#ffffff",
        
      },
    },
    subHeader: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    paginationButton: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    table: {
      style: {
        padding: "10px",
        backgroundColor: "#1F2123",
      },
    },
  };

  return (
    <DataTable
    customStyles={tableHeaderStyle}
    columns={columns}
    data={records}
    defaultSortField="_id"
    pagination
    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
    selectableRows
    selectableRowsHighlight
    highlightOnHover
    subHeader
    subHeaderComponent={
      <div className="all-users-header-group">
        <h6 className="all-users-title text-white">View & Manage All Portfolios</h6>
        <input
          className={`all-users-search-bar ${
            data1 == null ? "d-none" : ""
          } `}
          placeholder="Search"
          onChange={(e) => setFilter(e.target.value)}
          type="text"
        />
      </div>
    }
  />
  ); 
}

export default AllPortfolios;

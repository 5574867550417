import React, { useState, useEffect } from "react";
import { PORTFOLIO_API } from "../../../constants/api";
import { useParams, useNavigate } from "react-router-dom";
import "./SinglePortfolio.css";
function SinglePortfolio() {
  const { id } = useParams();
  const [portfolio, setPortfolio] = useState(null);
  const [nextProjectTitle, setNextProjectTitle] = useState(null);
  const [nextProjectImage, setNextProjectImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getPortfolios = async () => {
      console.log("my id " + id);
      const response = await fetch(PORTFOLIO_API + "/" + id);
      const portfolio = await response.json();

      if (response.ok) {
        setPortfolio(portfolio.data);
      }
    };

    const getNextPortfolios = async () => {
      const response = await fetch(PORTFOLIO_API + "/list");
      const portfolio = await response.json();
      if (response.ok) {
        console.log(portfolio.data);
        const index = await portfolio.data.findIndex((p) => p._id === id);
        if (index < portfolio.data.length) {
          console.log(index);
          const t = portfolio.data[index + 1].title;
          const i = portfolio.data[index + 1].featuredImage[0].secure_url;
          if (t) {
            setNextProjectTitle(t);
            setNextProjectImage(i);
          } else {
            setNextProjectTitle(portfolio.data[index - 1].title);
            setNextProjectImage(
              portfolio.data[index - 1].featuredImage[0].secure_url
            );
          }
        }
      }
    };

    getPortfolios();
    getNextPortfolios();
  }, [id]);
  const handleClick = () => {
    navigate("/#section-five");

    setTimeout(() => {
      const section = document.getElementById("section-five");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  return (
    <>
      {portfolio ? (
        <>
          <div className="container-fluid single-portfolio">
            <img
              className="single-portfolio-logo"
              src="/DavidOutlineseconeonesecond.svg"
              alt=""
            />

            <span className="text-center single-portfolio-title">
              {portfolio.title}
            </span>

            <img
              className="single-portfolio-featured"
              src={portfolio.featuredImage[0].secure_url}
              alt=""
            />

            <div className="single-portfolio-col-1 shadow-sm">MAC</div>
            <div className="single-portfolio-col-2 shadow-sm">2023</div>
            <div className="single-portfolio-col-3 shadow-sm">Full Service</div>
            <div className="single-portfolio-details shadow-sm">
              <h2 className="single-portfolio-details-heading">
                About Project
              </h2>
              <p className="single-portfolio-details-description">
                {portfolio.description}
              </p>
            </div>
            <div className="single-portfolio-gallery">
              <div className="container-fluid">
                <div className="row">
                  {portfolio.galleryImages.map((image) => (
                    <div className="col-md-4 p-2 shadow-sm">
                      <img
                        src={image.secure_url}
                        className="img-fluid single-portfolio-gallery-image"
                        alt=""
                      />
                    </div>
                  ))}
                  {portfolio.galleryImages.map((image) => (
                    <div className="col-md-4 p-2 shadow-sm">
                      <img
                        src={image.secure_url}
                        className="img-fluid single-portfolio-gallery-image"
                        alt=""
                      />
                    </div>
                  ))}
                  {portfolio.galleryImages.map((image) => (
                    <div className="col-md-4 p-2 shadow-sm">
                      <img
                        src={image.secure_url}
                        className="img-fluid single-portfolio-gallery-image"
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="container-fluid my-5">
                <div className="row">
                  <div className="col-md-6 pl-0 pr-2 shadow-sm">
                    <div className="single-portfolio-last-section-cta">
                      <h2 className="single-portfolio-last-section-cta-h">
                        Get Started Today!
                      </h2>
                      <p className="single-portfolio-last-section-cta-p">
                        Content Unlimited replaces AI-generated content,
                        unreliable freelancers & expensive agencies for one flat
                        monthly fee with unlimited content requests, delivered
                        lighting fast.
                      </p>
                      <div>
                        <button
                          onClick={handleClick}
                          className="single-portfolio-last-section-cta-b"
                        >
                          See Plans
                        </button>

                        <a
                          className="single-portfolio-last-section-cta-call-book"
                          target="_blank"
                          href="https://calendly.com/hypeappstore/30min"
                        >
                          or book a call
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pr-0 pl-2 single-portfolio-last-section-next-project">
                    <div className="row w-100">
                      <div className="col-md-6">
                        <h2 className="next-project-heading">Next Project</h2>
                      </div>
                      <div className="col-md-6 text-right">
                        <h2 className="next-project-heading">
                          {nextProjectTitle ? nextProjectTitle : <></>}
                        </h2>
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="col-md-12" style={{ height: "353px" }}>
                        {nextProjectImage ? (
                          <>
                            <img
                              className="next-project-image img-fluid"
                              src={nextProjectImage}
                              alt=""
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SinglePortfolio;

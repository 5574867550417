import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./EditUser.css";
import { toast } from "react-toastify";
import { USER_API } from "../../../constants/api";

function EditUser() {
  const { id } = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSingleUser = async () => {
      const response = await fetch(USER_API+"/" + id);
      const json = await response.json();

      if (response.ok) {
        setUsername(json.data.username);
        setPassword(json.data.password);
        setEmail(json.data.email);
        setFirstName(json.data.firstname);
        setLastName(json.data.lastname);
        setWebsite(json.data.website);
        setRole(json.data.role);
      }
    };

    fetchSingleUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const loadingToastId = toast.loading("Updating User...");
    const user = { username, firstname, lastname, email, website, password };
 
    try {
      const response = await fetch(USER_API+`/update/${id}`, {
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      } else {
        toast.dismiss(loadingToastId);
        toast.success("User updated successfully!");
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error("Error updating user:", error);
      toast.error("An error occurred while updating user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
    <h2 className="mb-5">Add New User</h2>

    <div className="header-body">
      <div className="card">
        <div className="card-body">
        <form className="row g-3" onSubmit={handleSubmit}>
         
             
                <div className="col-md-6">
                  <label htmlFor="inputEmail4">Username</label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="@sami5"
                    className="form-control"
                    id="inputEmail4"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="create password"
                    className="form-control"
                    id="inputPassword4"
                  />
                </div>
           
              <div className="col-md-12">
                <label htmlFor="inputAddress">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  placeholder="samwinch@gmail.com"
                  id="inputAddress"
                />
              </div>

            
                <div className="col-md-6">
                  <label htmlFor="inputEmail4">First Name</label>
                  <input
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Sam"
                    className="form-control"
                    id="inputEmail4"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4">Last Name</label>
                  <input
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Winch"
                    className="form-control"
                    id="inputPassword4"
                  />
                </div>
            

           
                <div className="col-md-6">
                  <label htmlFor="inputCity">Website</label>
                  <input
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    className="form-control"
                    id="inputCity"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputState">Role</label>
                  <select
                    disabled
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    id="inputState"
                    className="form-select"
                  >
                    <option value="user">Subscriber</option>
                    <option value="super-admin">Super Admin</option>
                    {/* <option>Author</option> */}
                    {/* <option>Editor</option> */}
                    <option value="admin">Admin</option>
                  </select>
                </div>
            

           
            {/* /.card-body */}
            <div className="col-md-12">
              {loading ? (
                <div>Loading...</div>
              ) : (
                <button type="submit" className="btn float-right"
                style={{ color: "white", background: "black" }}>
                  Update User
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  );
}

export default EditUser;

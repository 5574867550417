import React, { useEffect, useState } from "react";
import "./ClientDashboard.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import {
  BRAND_API,
  BRIEF_API,
  CLIENT_API,
  MESSAGE_API,
  USER_API,
} from "../../../constants/api";
import { Badge } from "reactstrap";
import { format } from "date-fns";
const StyledCell = styled.div`
  &.low {
    color: linear-gradient(to right, #1f2123, #0f64a2) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &.medium {
    color: linear-gradient(to right, #1f2123, #5b2b99) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &.high {
    color: linear-gradient(to right, #1f2123, #d09c17) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
function ClientDashboard() {
  const myId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [totalProjects, setTotalProjects] = useState(0);
  const [briefCounts, setBriefCount] = useState(0);
  const [completedCounts, setCompletedCounts] = useState(0);
  const [inCompletedCounts, setInCompletedCounts] = useState(0);
  const [totalChats, setTotaChats] = useState(0);
  const [projects, setProjects] = useState(null);
  const [users, setUsers] = useState(null);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  function getCssClass(value) {
    if (value === "Essential" || value === "E-commerce") return "low";
    else if (value === "Premium") return "medium";
    return "high";
  }
  const columns = [
    // {
    //   name: "Name",
    //   selector: (row) => {
    //    return users &&
    //     users.find(user => user._id === row.userId).username
    //   },
    //   sortable: true,
    // },
    {
      name: "Subscription",
      selector: (row) => (
        <>
          {row.projectCategory.name}
          {row.packageName === "Essential" ||
          row.packageName === "E-commerce" ? (
            <Badge
              style={{
                background: "linear-gradient(to right, #1f2123, #0f64a2)",
              }}
              className="ms-1"
            >
              {row.packageName}
            </Badge>
          ) : (
            <Badge
              style={{
                background: "linear-gradient(to right, #1f2123, #d09c17)",
              }}
              className="ms-2"
            >
              {row.packageName}
            </Badge>
          )}
        </>
      ),
      sortable: true,
      // width: "fit-content",
      minWidth: "270px",
      grow: 2,
    },
    // {
    //   name: "Title",
    //   selector: (row) => row.projectTitle,
    //   sortable: true,
    // },
    {
      name: "Amount",
      selector: (row) => row.currency.toUpperCase() + " " + row.price,
      sortable: true,
      // width: "fit-content",

      minWidth: "150px",
    },

    {
      name: "Purchase Date",
      selector: (row) => {
        const createdAtDate = new Date(row.createdAt);
        const day = createdAtDate.getDate();
        const monthIndex = createdAtDate.getMonth();
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const month = months[monthIndex];
        const year = createdAtDate.getFullYear();
        const formattedDate = `${day} ${month}, ${year}`;
        return formattedDate;
      },
      sortable: true,
      minWidth: "150px",
      // width: "fit-content",
    },
    {
      name: "Expiry Date",
      selector: (row) => {
        const expiryDate = new Date(row.expiryDate);
        const currentDate = new Date();

        return expiryDate < currentDate ? (
          <span
            className="bg-danger p-2 text-white"
            style={{ borderRadius: "30px" }}
          >
            {format(expiryDate, "dd MMM, yyyy")}
          </span>
        ) : (
          format(expiryDate, "dd MMM, yyyy")
        );
      },
      sortable: true,
      minWidth: "150px",
    },

    // {
    //   name: "Status",
    //   selector: (row) => "In Progress",
    //   sortable: true,
    // },
    // {
    //   name: "Package",
    //   selector: (row) => row.packageName,
    //   sortable: true,
    //   cell: (row) => (

    //   row.packageName === "Essential" || row.packageName === "E-commerce"?

    //   <Badge style={{background: "linear-gradient(to right, #1f2123, #0f64a2)"}} className="ms-3">
    //   {row.packageName}
    //  </Badge>
    //   :
    //   <Badge style={{background: "linear-gradient(to right, #1f2123, #d09c17)"}} className="ms-3">
    //  {row.packageName}
    // </Badge>

    //   ),
    // },
    {
      name: "View Briefs",
      selector: (row) => (
        <Link
          to={`/client-auth/client-order-page/${row._id}`}
          className="btn btn-sm"
          style={{ color: "white", backgroundColor: "black" }}
        >
          View Briefs
        </Link>
      ),
      minWidth: "150px",
      // minWidth: "100px",
    },
  ];
  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(USER_API + "/users/list");
      const json = await response.json();
      if (response.ok) {
        setUsers(json.data);
      }
    };

    const getProjects = async () => {
      try {
        setLoading(true);
        const projects = await fetch(CLIENT_API + "/" + myId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const json = await projects.json();
        if (projects.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            setTotalProjects(json.data.length);
            const formattedData = json.data.map((record) => {
              const createdAtDate = new Date(record.createdAt);
              const day = createdAtDate.getDate();
              const monthIndex = createdAtDate.getMonth();
              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              const month = months[monthIndex];
              const year = createdAtDate.getFullYear();
              const formattedDate = `${day} ${month}, ${year}`;
              return { ...record, formattedDate };
            });
            setProjects(formattedData);
            setRecords(formattedData);
          }
        } else {
          setRecords([]);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    const getClientChats = async () => {
      const response = await fetch(MESSAGE_API + "/client/" + myId);
      const clients = await response.json();
      console.log(clients.data);
      if (response.ok) {
        if (clients.data == null) {
          // alert("data is null")
        } else {
          setTotaChats(clients.data.length);
        }
      }
    };
    const getBriefs = async () => {
      const response = await fetch(BRIEF_API + "/" + myId);
      const briefs = await response.json();

      if (response.ok) {
        if (briefs.data.briefCount == null) {
          // alert("data is null")
        } else {
          console.log(briefs);
          setBriefCount(briefs.data.briefCount);
          setCompletedCounts(briefs.data.completedBriefs);
          setInCompletedCounts(briefs.data.briefsInProgress);
        }
      }
    };

    fetchUsers();
    getProjects();
    getBriefs();
    getClientChats();
  }, [myId]);
  const setFilter = (value) => {
    const result = projects.filter((record) => {
      return (
        record.currency.toLowerCase().includes(value.toLowerCase()) ||
        record.price.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.projectCategory.name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        record.packageName.toLowerCase().includes(value.toLowerCase()) ||
        record.formattedDate
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    });

    setRecords(result);
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="mb-5">Dashboard</h2>
        <div className="header-body">
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-1">
                        Progress
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {inCompletedCounts}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i className="lni lni-bar-chart"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {/* <span className="text-success mr-2"><i className="fas fa-arrow-up" /> 12%</span> */}
                    <span className="text-nowrap">In progress briefs</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-1">
                        Completed
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {completedCounts}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                        <i className="lni lni-checkmark-circle"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {/* <span className="text-warning mr-2"><i className="fas fa-arrow-down" /> 1.10%</span> */}
                    <span className="text-nowrap">Completed briefs</span>
                  </p>
                </div>
              </div>
            </div>

            {/* packages  */}
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-1">
                        Subscriptions
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {totalProjects}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                        <i className="lni lni-layers"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {/* <span className="text-success mr-2"><i className="fa fa-arrow-up" /> 3.48%</span> */}
                    <span className="text-nowrap">Total subscriptions</span>
                  </p>
                </div>
              </div>
            </div>
            {/* end packages  */}
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-1">
                        Briefs
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {briefCounts}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="lni lni-inbox"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {/* <span className="text-danger mr-2"><i className="fas fa-arrow-down" /> 3.48%</span> */}
                    <span className="text-nowrap">Total briefs</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-body mt-5">
          <div className="row mb-1 d-flex justify-content-end">
            <div className="col-md-4 col-sm-6">
              <form action="">
                <input
                  className={`form-control ${
                    projects == null ? "d-none" : ""
                  } `}
                  placeholder="Search"
                  onChange={(e) => setFilter(e.target.value)}
                  type="text"
                />
              </form>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={records}
              pagination
              progressPending={loading}
              progressComponent={
                <div className="text-center my-2 bg-white">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
              // Add custom styles or classes here using Bootstrap classes
              className="table table-striped table-hover"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientDashboard;

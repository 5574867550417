import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./Team.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import SettingsIcon from "@mui/icons-material/Settings";
import CancelIcon from "@mui/icons-material/Cancel";
import { DEPARTMENT_API, TEAM_API, USER_API } from "../../../constants/api";

function Team() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [role, setRole] = useState("");
  const [department, setDepartment] = useState("");
  const [allDepartments, setAllDepartments] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [selected_id, setSelectedId] = useState("");
  const token = localStorage.getItem("token");
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    console.log("Clicked row ID:", id);
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const getAllDepartments = async () => {
      const response = await fetch(DEPARTMENT_API + "/list");
      const all_departments = await response.json();
      if (response.ok) {
        setAllDepartments(all_departments.data);
      }
    };

    getAllDepartments();
  }, []);
  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, row._id)}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <SettingsIcon style={{ color: "#828282", fontSize: "16px" }} />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom", // Change to bottom
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleClose}>
              <Button
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <EditIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <Link
                  //   to={`edit-user/${selected_id}`}
                  style={{ color: "#2C2C2C", textDecoration: "none" }}
                >
                  Edit
                </Link>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                onClick={() => handleDelete(selected_id)}
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <DeleteIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Delete
              </Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Button
                style={{
                  color: "#2C2C2C",
                  fontFamily: "HelveticaNeueLTStd",
                  fontSize: "14px",
                }}
                startIcon={
                  <CancelIcon style={{ color: "#2C2C2C", fontSize: "14px" }} />
                }
              >
                Suspend
              </Button>
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const [data1, setData] = useState(null);

  const [records, setRecords] = useState([]);

  const [update, setUpdate] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(TEAM_API + "/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        if (json.data == null) {
          setRecords([]);
        } else {
            const filtered = json.data.filter(data => data.isDeleted === false)
            if(filtered){
                console.log(filtered);
                setData(filtered);
                setRecords(filtered);
            }else{
                setRecords([]);
            }
         
        }
      } else {
        setRecords([]);
      }
    };

    fetchUsers();
  }, [update]);

  const setFilter = (value) => {
    const result = data1.filter((record) => {
      if (record.firstname == null || record.firstname == null) {
        return (
          // record._id.toString().includes(value.toLowerCase()) ||
          record.username.toLowerCase().includes(value.toLowerCase()) ||
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.role.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        return (
          // record._id.toString().includes(value.toLowerCase()) ||
          record.username.toLowerCase().includes(value.toLowerCase()) ||
          record.firstname.toLowerCase().includes(value.toLowerCase()) ||
          record.lastname.toLowerCase().includes(value.toLowerCase()) ||
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.role.toLowerCase().includes(value.toLowerCase())
        );
      }
    });

    setRecords(result);
  };

  const handleDelete = (myId) => {
    console.log(myId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ml-2",
        cancelButton: "btn btn-danger btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            console.log("chako:"+d_id)
            const response = await fetch(TEAM_API + "/delete/" + d_id, {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${token}`, 
              },
            });
            setUpdate(Math.random());
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "User is safe :)",
            icon: "error",
          });
        }
      });
  };

  const tableHeaderStyle = {
    headRow: {
      style: {
        backgroundColor: "#1F2123",
      },
    },
    headCells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        backgroundColor: "#1F2123",
        color: "#ffffff",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    paginationButton: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    table: {
      style: {
        padding: "10px",
        backgroundColor: "#1F2123",
      },
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Creating New User...");
    const user = {
      username,
      email,
      password,
      firstname,
      lastname,
      website,
    };

    console.log("check: " + user);

    try {
      const response = await fetch(TEAM_API + "/create", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("User Created successfully!");
        setUsername("");
        setPassword("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setWebsite("");
        setUpdate(Math.random());
      }
    } catch (error) {
      console.error("Error creating user:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while creating user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataTable
      customStyles={tableHeaderStyle}
      columns={columns}
      data={records}
      defaultSortField="_id"
      pagination
      paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderComponent={
        <div className="all-users-header-group">
          <div>
            <button
              type="button"
              className="add-team-member"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
            >
              Add New Member
            </button>
            <div
              className="modal fade bd-example-modal-lg"
              tabIndex={-1}
              role="dialog"
              data-backdrop="static"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content py-2">
                  <div className="container-fluid">
                    <div className="row">
                      {/* left column */}
                      <div className="col-md-12">
                        {/* general form elements */}
                        <div className="card card-light">
                          <div className="card-header">
                            <h3 className="card-title">Add New Member</h3>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            > 
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          {/* /.card-header */}
                          {/* form start */}
                          <form onSubmit={handleSubmit}>
                            <div className="card-body">
                              <div className="form-row">
                                <div className="form-group col-md-6 text-left">
                                  <label htmlFor="inputEmail4">Username</label>
                                  <input
                                    type="text"
                                    value={username}
                                   
                                    onChange={(e) =>
                                      setUsername(e.target.value)
                                    }
                                    placeholder="@sami5"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                                <div className="form-group col-md-6 text-left">
                                  <label htmlFor="inputPassword4">
                                    Password
                                  </label>
                                  <input
                                
                                    type="password"
                                    
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    placeholder="create password"
                                    className="form-control"
                                    id="inputPassword4"
                                  />
                                </div>
                              </div>
                              <div className="form-group text-left">
                                <label htmlFor="inputAddress">Email</label>
                                <input
                                  type="email"
                                
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="form-control"
                                  placeholder="samwinch@gmail.com"
                                  id="inputAddress"
                                />
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 text-left">
                                  <label htmlFor="inputEmail4">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    value={firstname}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    placeholder="Sam"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                                <div className="form-group col-md-6 text-left">
                                  <label htmlFor="inputPassword4">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    value={lastname}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                    placeholder="Winch"
                                    className="form-control"
                                    id="inputPassword4"
                                  />
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 text-left">
                                  <label htmlFor="inputCity">Website</label>
                                  <input
                                    type="text"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                    className="form-control"
                                    id="inputCity"
                                  />
                                </div>
                              </div>

                              <div className="form-check text-left">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck1"
                                >
                                  Send the new user an email about their
                                  account.
                                </label>
                              </div>
                            </div>
                            {/* /.card-body */}
                            <div className="card-footer d-flex justify-content-end">
                              {loading ? (
                                <div>Loading...</div>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn add-new-button"
                                >
                                  Create New Member
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* /.row */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <input
            className={`all-users-search-bar ${data1 == null ? "d-none" : ""} `}
            placeholder="Search"
            onChange={(e) => setFilter(e.target.value)}
            type="text"
          />
        </div>
      }
    />
  );
}

export default Team;

import React, {useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css"
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import Logo from "./Logo";
import { ReactComponent as LogoWhite } from "../assets/images/logos/adminprowhite.svg";
import user1 from "../assets/images/users/user4.jpg";
import { NOTIFICATION_API } from "../constants/api";
import { UpdateNotification } from "./FullLayout";

const Header = () => {
  const navigate = useNavigate();
  const myId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [notificationCounter, setNotificationCounter] = React.useState("0");
  const [noti, setNoti] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const {update, setUpdate} = useContext(UpdateNotification)

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [dropdown2Open, setDropdown2Open] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setDropdown2Open((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  React.useEffect(() => {
    const getNotification = async () => {
      const response = await fetch(NOTIFICATION_API + "/" + myId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        setNoti(json.data);
        setNotificationCounter(json.unreadCount);
      }
    };

    getNotification();
  }, [update]);
  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const createdAtDate = new Date(timestamp);
    const timeDifference = now - createdAtDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else {
      return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    }
  };
  const handleRead = async (id) => {
    const response = await fetch(NOTIFICATION_API + "/" + id, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await response.json();
    if (response.ok) {
      setUpdate(Math.random());
    }
  }; 
  return (
    <Navbar color="white" light expand="md" className="fix-header">
      <div className="d-flex align-items-center">
        <div className="d-lg-block d-none me-5 pe-3">
          <Logo />
        </div>
        {/* <NavbarBrand href="/">
          <LogoWhite className="d-lg-none" />
    
        </NavbarBrand> */}
        <Button
          color="dark"
          className=" d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
      </div>
      <div className="hstack gap-2">
        <Button
          color="dark"
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x"></i>
          ) : (
            <i className="bi bi-three-dots-vertical"></i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen}>
        <Nav className="me-auto" navbar>
          {/* <NavItem>
            <Link to="/starter" className="nav-link">
              Starter
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about" className="nav-link">
              About
            </Link>
          </NavItem>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle caret nav>
              DD Menu
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>Option 1</DropdownItem>
              <DropdownItem>Option 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Reset</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </Nav>
        <div className="d-flex justify-content-end">
        <Dropdown className="hide-focus-border" isOpen={dropdown2Open} toggle={toggle2}>
          <DropdownToggle className="hide-focus-border" color="transparent">
            <h2 type="button" class="btn position-relative hide-focus-border">
              {/* <button type="button" className="btn btn-primary position-relative"> */}
              <i className="bi bi-bell-fill hide-focus-border"></i>
              <span className="position-absolute top-30 end-10 badge rounded-pill bg-danger">
                {notificationCounter}
                <span className="visually-hidden">unread messages</span>
              </span>
            </h2>
          </DropdownToggle> 
          <DropdownMenu className="">
            {noti &&
              noti.map((notification) => (
                <>
                  <DropdownItem
                   className={`${notification.is_read? "" : "read-notification"}`}
                    onClick={() => handleRead(notification._id)}
                    header={notification.is_read ? true : undefined}
                  >
                    {" "}
                    {notification.message}
                  </DropdownItem>
                  <DropdownItem header  className={`${notification.is_read? "" : "read-notification"}`}>
                    {" "}
                    {getTimeAgo(notification.createdAt)}
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              ))}
          </DropdownMenu>
        </Dropdown>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="hide-focus-border" color="transparent">
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Info</DropdownItem>
            {/* <DropdownItem>My Account</DropdownItem>
            <DropdownItem>Edit Profile</DropdownItem> */}
            <DropdownItem divider />
            {/* <DropdownItem>My Balance</DropdownItem> */}
            <DropdownItem>
              {" "}
              {/* <Link
                to="/client-auth/inbox"
                style={{ textDecoration: "none", color: "#1e2a35" }}
              >
                Inbox
              </Link> */}
            </DropdownItem>
            <DropdownItem onClick={logout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        </div>
      
      </Collapse>
    </Navbar>
  );
};

export default Header;
